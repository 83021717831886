import moment from "moment";

export  class Specification {
    constructor(obj) {
        this.Project_ID= obj.Project_ID;
        this.Spec_ID= obj.Spec_ID;
        this.SpecDescr= obj.SpecDescr;
        this.SpecItemDescr= obj.SpecItemDescr;
       // this.SpecItemValue= obj.ValueType === 2?moment(obj.SpecItemValue).format('DD/MM/YY'):obj.SpecItemValue;
        this.SpecItemValue= obj.SpecItemValue;
        this.ValueType= obj.ValueType;
    }
}

