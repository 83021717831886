import moment from "moment";
import {ProjectDetails} from "@/wl-core/api/classes/ProjectDetails";

export class ProjectsMaint extends ProjectDetails {
    constructor(obj) {
        super(obj);
        this.Partner_ID = obj.Partner_ID;
        this.Zone_ID = obj.Zone_ID;
        this.ZoneDescr = obj.ZoneDescr;
        this.DayGroup = obj.DayGroup;
        this.DayGroupName = obj.DayGroupName;
        this.Branch_ID = obj.Branch_ID;
        this.BranchCode = obj.BranchCode;
        this.BranchAddress = obj.BranchAddress;
        this.BranchAreaName = obj.BranchAreaName;
        this.PartnerBrandName = obj.PartnerBrandName;
        this.Texnitis_ID = obj.Texnitis_ID;
        this.TexnitisName = obj.TexnitisName;
        this.Event_ID = obj.Event_ID;
        this.DateTexnEnim = obj.DateTexnEnim;
        this.DateSintir = obj.DateSintir;
        this.StatusProj_ID = obj.StatusProj_ID;
        this.StatusProjDescr = obj.StatusProjDescr;
        this.DeviceDateReaded = obj.DeviceDateReaded;
        this.LastTexnitisName = obj.LastTexnitisName;
        this.SinPerYear = obj.SinPerYear;
        this.DoneMaint = obj.DoneMaint;
        this.Omades = obj.Omades; // {OmadaDescr,Value}
        //{Description,Type}
        this.ExtraInfo = obj.ExtraInfo || [];
        this.setProblem();
    }

    setProblem() {
        this.problem = 'Χωρίς πρόβλημα'
        if (this.scheduledMaintenanceNotDone() || this.hasSkippedMaintenance()) {
            this.problem = 'Με πρόβλημα'
        }
    }

    hasMultipleSintirPerMonth() {
        return this.SinPerYear > 12
    }

    isMaintenanceDoneThisMonth() {
        return this.maintenancesPerMonth() <= Number(this.DoneMaint);
    }

    maintenancesPerMonth() {
        if (this.SinPerYear || this.SinPerYear === 0) {
            return Math.ceil((this.SinPerYear / 12))
        } else {
            return 0;
        }
    }

    canEdit() {
        return (!this.isMaintenanceDoneThisMonth()) && this.DateTexnEnim === null;
    }

    getColor() {
        if (this.DateTexnEnim) return 'rgba(0,73,200,0.13)';
        if (this.DoneMaint >= 1 && this.DoneMaint < this.maintenancesPerMonth()) return 'rgba(0,200,180,0.13)'
        if (this.isMaintenanceDoneThisMonth()) return 'rgba(0,200,33,0.13)';
        return ''
    }

    hasMaintenanceOnMonth(MM) {
        return this.SixnotitaSintirisis['M' + MM] === true
    }

    /**
     *
     * @param moment a moment object
     * @returns {*}
     */
    formatMonth(moment) {
        return moment.format('MM')
    }

    /**
     *
     * @param returnMoment
     * @returns {*}
     */
    lastMaintenanceMonth(returnMoment) {
        let month = moment().endOf('month').subtract(1,'month').endOf('month');
        let i = 2;
        while (i < 12 && !this.hasMaintenanceOnMonth(this.formatMonth(month))) {
            month = moment().subtract(i, 'months').endOf('month')
            i++;
        }
        if (!returnMoment) {
            return this.formatMonth(month);
        } else {
            return month
        }
    }


    hasSkippedMaintenance() {

        let lastMaintenance = moment(this.DateSintir).endOf('month')
        let lastMaintMonth = this.lastMaintenanceMonth(true)
        return lastMaintenance < lastMaintMonth;
    }

    scheduledMaintenanceNotDone() {
        if (!this.DateTexnEnim || this.isMaintenanceDoneThisMonth()) {
            return false;
        } else {
            if (moment(this.DateTexnEnim).endOf('day') < moment().endOf('day')) {
                return true
            }
        }
    }

    hasMaintenanceThisMonth() {
        let month = moment().format('MM')
        if (this.SixnotitaSintirisis['M' + month] !== undefined) {
            return this.SixnotitaSintirisis['M' + month] === true;
        } else {
            return false;
        }
    }


}
