import store from "../../store";
import jsCookies from "js-cookie";


function setLayoutView(to){
    if(to.query.layout){
        store.commit('layout/setLayoutView',to.query.layout)
    }
}
function loginViaToken(to, from, next, token) {
    if (token) {
        store.dispatch('getSubscriptionViaToken', token).then(
            resp => {
                let currentUser = store.getters['userDetails'];
                nextRoute(currentUser, to, from, next);
            }
        ).catch(() => next({name: 'login', query: {dontRedirect: true}}));
    }
}

function loginViaCredentials(to, from, next, credentials) {
    if (credentials !== null) {
        store.dispatch('getSubscriptionDetails', {
            username: credentials.username,
            password: credentials.password
        }).then(resp => {
                let currentUser = store.getters['userDetails'];
                nextRoute(currentUser, to, from, next);
            }
        ).catch(() => next({name: 'login', query: {dontRedirect: true}}));
    }
}


function getCredentials(to) {
    if (to.query.p && to.query.u) {
        return {username: to.query.p, password: to.query.u}
    } else {
        return null
    }
}

function getToken(to) {
    if (to.query.token) {
        return to.query.token
    } else {
        return jsCookies.get('auth')
    }
}

function nextRoute(currentUser, to, from, next) {
    const requiresAuth = to.meta.requiresAuth;
    const loginpath = to.path;
    if (!currentUser.Subscription) {
        next({name: 'Σύνδεση', query: {from: loginpath, dontRedirect: true}});
    } else {
        if (loginpath !== '/login') {
            next();
        } else {
            next({name: 'Home'})
        }
    }
}

export default {
    beforeEach(to, from, next) {
        let currentUser = {};
        setLayoutView(to);
        if(!to.query.token){
            store.dispatch('getUserDetailsFromSession');
        }
        if (!to.query.dontRedirect && to.meta.requiresAuth) {
            if (!store.getters['userDetails'].Subscription) {
                const token = getToken(to);
                const credentials = getCredentials(to);
                if(token){
                    loginViaToken(to,from,next,token)
                }else if(credentials){
                    loginViaCredentials(to,from,next,credentials)
                }else{
                    nextRoute(currentUser,to,from,next)
                }
            } else {
                currentUser = store.getters['userDetails'];
                nextRoute(currentUser, to, from, next);
            }
        } else {
            next();
        }
    }

}

