import axios from '../axios';
import config from "../../../config";
import UserDetails from "../classes/UserDetails";

export default {

    getSubscriptionViaToken(token) {
        return axios.get(config.host + '/login/LoginData', {
            headers: {
                Token: token
            },
        }).then(resp => {
            let userDetails = new UserDetails(resp.data.Data);
            return Promise.resolve(userDetails)
        })
    },
    changePassword(username, oldPassword, newPassword) {
        return axios.put(config.host + '/login/ChangePassword', {
            Username: username,
            OldPassword: oldPassword,
            NewPassword: newPassword
        }).then(resp => Promise.resolve(resp.data.Data))
    },
    getSubscriptionDetails(username, password, rememberMe) {
        return axios.get(config.host + '/login/LoginData', {
            params: {
                RememberMe: rememberMe || false
            },
            headers: {
                username: username,
                passWord: password
            }
        }).then(resp => {
            let userDetails = new UserDetails(resp.data.Data);
            return Promise.resolve(userDetails)
        })
    }
}
