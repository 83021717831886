import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark:true,
    themes: {
      dark: {
        topbar:'#000',
        primary: '#226ce0',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background:'#141616',
        sidebar:'#1f1f1f',
        callout: '#F52626',
        maintenance: '#3f68ff',
        task:'#3d4246'
      },
      light: {
        topbar:'#fff',
        primary: '#4786ee',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background:'#edf0f5',
        sidebar:'#292929',
        callout: '#F52626',
        maintenance: '#444BA2',
        task:'#3d4246'
      },
    },
  },
    lang: {
    },
});
