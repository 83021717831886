<template>
    <v-app-bar
            :dense="dense"
            app
            :clipped-left="clippedLeft"
            :clipped-right="clippedRight"
            :color="color"
            :dark="dark"
    >
        <v-container class="d-flex align-center" style="max-width: 1920px" fluid>
            <v-app-bar-nav-icon v-if="!isMobile && showSidebarIcon" @click.stop="toggleExpandOnHover()"></v-app-bar-nav-icon>
            <v-app-bar-nav-icon v-else v-else-if="isMobile && showSidebarIcon" @click.stop="toggleSidebar()"></v-app-bar-nav-icon>
            <v-toolbar-title>
                <portal-target name="toolbar-title"/>
            </v-toolbar-title>
            <v-toolbar-items class="ml-6">
                <portal-target name="toolbar-items"/>
            </v-toolbar-items>
            <v-spacer/>
            <portal-target name="toolbar-actions"/>
            <slot name="after-actions">
            </slot>
        </v-container>
    </v-app-bar>
</template>

<script>
    import {mapGetters, mapMutations} from "vuex";
    export default {
        props:{
            clippedLeft:Boolean,
            clippedRight:Boolean,
            dark:Boolean,
            dense:Boolean,
            showSidebarIcon:Boolean,
            color:String
        },
        name: "Topbar",
        computed: {
            ...mapGetters('layout', [
                    "expandSidebarOnHover",
                    "showSidebar",
                    "isMobile"
                ]
            )
        },
        methods: {
            ...mapMutations('layout', [
                    "toggleExpandOnHover",
                    "toggleSidebar"

                ]
            )
        }
    }
</script>

<style scoped>

</style>
