import {Filters} from "./Filters";

export default class DeptMoveDetails {
    constructor(obj) {
        this.Partner_ID = obj.Partner_ID;
        this.Branch_ID = obj.Branch_ID;
        this.BranchAddress = obj.BranchAddress;
        this.Project_ID = obj.Project_ID;
        this.ProjectName = obj.ProjectName;
        this.Hmerom = Filters.fullDate(obj.Hmerom);
        this.HmeromOriginal = obj.Hmerom;
        this.InvoiceDescr = obj.InvoiceDescr;
        this.NumberPar = obj.NumberPar;
        this.Description = obj.Description;
        this.Xreosi = obj.Xreosi;
        this.Pistosi = obj.Pistosi;
        this.Balance = obj.Balance;
        this.StandBy = obj.StandBy;
    }
    transactionType(){
        if(this.Pistosi && !this.Xreosi){
            return 'Πίστωση'
        }else if (!this.Pistosi && this.Xreosi) {
            return 'Χρέωση'
        }else if (this.Xreosi && this.Pistosi){
            return 'Μετρητοίς'
        }else{
            return  '-'
        }
    }

}
