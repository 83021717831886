<template>
  <div>
    <portal to="toolbar-title"> Αναφορές Πελατών</portal>

    <filter-iterator
        title="Πελάτες"
        :is-mobile="isMobile"
        show-filter-modal
        search-label="αναζήτηση"
        module-name="partnersHandler"
        :filters="[
                         {key:'PartnerJobName', name:'Εππάγελμα'},
                         {key:'PartnerAreaName', name:'Περιοχή'},
                         {key:'PartnerLost', name:'Χαμμένος'}
                     ]
                     "
        :data-set="partners">
      <template v-slot:desktop="data">
        <v-data-table :items="data.data" :headers="headers" :search="data.search"></v-data-table>
      </template>
      <template v-slot:mobile="data">
        <v-data-iterator
            :items="data.data"
            :search="data.search"
        >
          <template v-slot:default="props">
            <v-row dense>
              <v-col
                  v-for="partner in props.items"
                  :key="partner.Partner_ID"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
              >
                <v-card>
                  <v-toolbar dense flat>
                    <v-toolbar-title>
                      {{ partner.Partner_ID }}, {{ partner.PartnerBrandName }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu
                        bottom
                        left
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            dark
                            icon
                            v-bind="attrs"
                            v-on="on"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item>
                          <v-list-item-title>Αναλυτικές Κινήσεις</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-toolbar>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle> Επάγγελμα</v-list-item-subtitle>
                        {{ partner.PartnerJobName }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle> Διεύθυνση</v-list-item-subtitle>
                        {{ partner.PartnerInvAddress }} {{ partner.PartnerAreaName }} {{ partner.PartnerPostCode }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-card-actions>
                    <v-btn icon href="tel:+3000000000">
                      <v-icon>mdi-phone</v-icon>
                    </v-btn>

                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>

        </v-data-iterator>
      </template>
    </filter-iterator>
    <v-row>
      <v-col lg="4">
        <v-card>
          <v-card-title> Χαμένοι</v-card-title>
        </v-card>
      </v-col>
      <v-col lg="4">
        <v-card>
          <v-card-title> Ενεργοί</v-card-title>
        </v-card>
      </v-col>
      <v-col lg="4">
        <v-card>
          <v-card-title> Χρωστάνε</v-card-title>
        </v-card>
      </v-col>
      <v-col lg="4">
        <v-card>
          <v-card-title> Κακοπληρωτές </v-card-title>
        </v-card>
      </v-col>
      <v-col lg="4"> <v-card flat style="border: 2px dashed; background-color: transparent" ripple >
        <v-card-title> <v-icon>mdi-plus</v-icon> Προσθήκη ομάδας </v-card-title>
      </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import FilterIterator from "@/wl-datagrid-handler/FilterIterator";

export default {
  name: "Partners",
  components: {FilterIterator},
  data: () => ({
    headers: [
      {value: 'PartnerInvAddress', text: 'Περιοχή'},
      {value: 'PartnerBrandName', text: 'Επωνυμία'},
      {value: 'PartnerAreaName', text: 'Περιοχή'},
    ]
  }),
  created() {
    this.getPartners();
  },
  computed: {
    ...mapGetters("layout", [
      "isMobile"
    ]),
    ...mapState("db", [
          "partners"
        ]
    )
  },
  methods: {
    ...mapActions('db', [
      "getPartners"
    ])
  }
}
</script>

<style scoped>

</style>
