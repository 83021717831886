import axios from '../axios';
import config from "../../../config";

/**
 * TypeID
 * ------------
 *
 */

export default {

    /**
     * ListIds are a string containing the typeIds splitted by ','
     * @param listIds
     * @returns {Promise<AxiosResponse<any>>}
     */

}
