<template>
  <v-navigation-drawer
      app
      :value="showSidebar"
      @click.outside="toggleSidebar()"
      :expand-on-hover="expandSidebarOnHover"
      :left="left"
      :clipped="clipped"
      :fixed="fixed"
      :color="color"
      :dark="dark"
      class="elevation-4"
  >
    <v-list-item>
      <v-list-item-content>
        WINLIFT
      </v-list-item-content>
    </v-list-item>
    <v-list shaped>
      <template v-for="item in sidebarItems">
        <!-- Heading -->
        <v-row
            v-if="item.heading"
            :key="item.heading"
            align="center"
        >
          <v-col cols="6">
            <v-subheader v-if="item.heading">
              {{ item.heading }}
            </v-subheader>
          </v-col>
        </v-row>
        <!--- Group -->
        <v-list-group
            v-else-if="item.children"
            :key="item.text"
            :prepend-icon="item.icon"
            color="secondary"
        >
          <template v-slot:activator>
            <v-list-item  :color="activeColor">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item
              :color="activeColor"
              v-for="(child, i) in item.children"
              :key="i"
              :to="child.to"
          >
            <v-list-item-action
                v-if="child.icon">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ child.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!--- Normal link -->
        <v-list-item
            :color="activeColor"
            shaped
            v-else
            :key="item.text"
            :to="item.to"
        >
          <v-list-item-action
          >
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "Sidebar",
  computed: {
    ...mapGetters('layout', [
          "sidebarItems",
          "expandSidebarOnHover",
          "showSidebar",
        ]
    ),
    ...mapMutations('layout',[
      "toggleSidebar"
    ])
  },
  props: {
    color: {type: String, default: '', required: false},
    fixed: {type: Boolean, default: null, required: false},
    clipped: {type: Boolean, default: null, required: false},
    left: {type: Boolean, default: null, required: false},
    dark: {type: Boolean, default: null, required: false},
    activeColor: {type: String, default: null, required: false}
  }
}
</script>

<style scoped>

</style>
