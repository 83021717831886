import api from '../api'
import db from '../db'
import axios from "@/wl-core/api/axios";
import config from "@/config";
import {IssueType} from "@/wl-core/api/classes/IssueType";
import store from "@/store";

export default {
    namespaced: true,
    state: {
        partners: [],
        branches: [],
        projects: [],
        issues: [],
        syncQueue: [],
        timer: null,
        timerTick: 120,
        remainingTime: 120,
        countdown: null,
    },
    actions: {

        syncAll({commit, dispatch}, resetTimer) {
            if (resetTimer) {
                dispatch('autoSync')
            }
            return Promise.all([dispatch('syncPartners')])
        },
        autoSync({commit, dispatch, state}) {
            commit('clearTimer')
            let timer = setInterval(
                () => {
                    dispatch('syncAll');
                    commit('setCountdown')
                },
                state.timerTick * 1000)
            commit('setTimer', timer)
            commit('setCountdown');
        },
        syncPartners({dispatch}) {
            db.partners.getPartnersDbSync()
                .then(syncId => api.partners.getPartners({Sync_ID: syncId}))
                .then(partners => {
                    if (partners.length) {
                        dispatch('getPartners')
                    }
                })
                .catch(err => {
                    store.commit('hideLoading')
                })
        },
        getPartners({commit}) {
            commit("showLoading", null, {root: true})
            api.partners.getPartners({Sync_ID: null}).then(partners => {
                commit('setPartners', partners)
                commit("hideLoading", null, {root: true})
            }).catch(err => {
                store.commit('hideLoading')
            })
        },
        getIssues({commit}) {
            commit("showLoading", null, {root: true})
            api.issues.getIssues().then(issues => {
                commit('setIssues', issues)
                commit("hideLoading", null, {root: true})
            }).catch(err => {
                store.commit('hideLoading')
            })
        },

        getIssueTypes({commit}) {
            commit("showLoading", null, {root: true})
            api.issues.getIssueTypes().then(issues => {
                commit('setIssueTypes', issues)
                commit("hideLoading", null, {root: true})
            }).catch(err => {
                store.commit('hideLoading')
            })
        },


    }
    ,
    mutations: {
        setCountdown(state) {
            clearInterval(state.countdown)
            state.countdown = null;
            state.remainingTime = state.timerTick
            state.countdown = setInterval(() => {
                state.remainingTime--
            }, 1000)
        },
        clearTimer(state) {
            clearInterval(state.timer)
            state.timer = null;
        },
        setTimer(state, timer) {
            state.timer = timer;
        },
        setPartners(state, partners) {
            state.partners = partners;
        }
    }


}
