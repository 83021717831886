<template>
  <div>
    <v-toolbar style="position:sticky; top:0; z-index: 100" flat>
      <v-text-field label="Αναζήτηση" hide-details v-model="search"></v-text-field>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-data-iterator hide-default-footer
                     disable-pagination
                     no-results-text="Δεν βρέθηκαν αποτελέσματα"
                     no-data-text="Δεν βρέθηκαν αποτελέσματα"
                     :items="dayGroups"
                     :search="search"
    >
      <template v-slot:default="props">
        <v-list v-if="!loading">
          <v-list-item style="border-bottom: 1px solid #ffffff22;"
                       :style="'background-color:'+(isDayGroupDone(dayGroup)?'#00c85320':'')" v-for="dayGroup in props.items"
                       @click="selectDayGroupsClick(dayGroup.DayGroup)">
            <v-list-item-content>
              <v-list-item-title>
                {{ dayGroup.DayGroupName }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-progress-circular
                  :rotate="360"
                  :width="5"
                  size="40"
                  :value="completionPercent(dayGroup)"
                  :color="dayGroup.DoneMaint >= dayGroup.TotalMaint?'success':''"
              >
                <span style="font-size: 0.7em"> {{ dayGroup.DoneMaint }}/{{ dayGroup.TotalMaint }} </span>

              </v-progress-circular>
            </v-list-item-action>
            <v-list-item-action>
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-skeleton-loader type="list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line" v-else>

        </v-skeleton-loader>
      </template>

    </v-data-iterator>


  </div>

</template>

<script>
import api from '@/wl-core/api'
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "ZoneDayGroupModal",
  data: () => ({
    dayGroups: [],
    search:"",
  }),
  created() {
    this.getDayGroupsOfZone();
  },
  watch: {
    zoneId() {
      this.getDayGroupsOfZone()
    }
  },
  computed: {
    ...mapGetters([
      "loading"
    ])
  },
  methods: {
    ...mapMutations([
      "hideLoading",
      "showLoading",
    ]),
    completionPercent(dayGroup) {
      try {
        return (dayGroup.DoneMaint / dayGroup.TotalMaint) * 100
      } catch (e) {
        return 0
      }
    },
    isDayGroupDone(dayGroup) {
      return dayGroup.DoneMaint === dayGroup.TotalMaint
    },
    getDayGroupsOfZone() {
      this.showLoading()
      this.search = ""
      api.projects.getDayGroupsOfZone(this.zoneId).then(dayGroups => {
        this.dayGroups = dayGroups;
        this.hideLoading()
      })
    },
    selectDayGroupsClick(id) {
      this.$emit('dayGroupSelect', id)
    }
  },
  props: {
    zoneId: {type: Number, default: null, required: false}
  }
}
</script>

<style scoped>

</style>
