export default ({
    namespaced: true,
    state() {
        return {
            data: [],
            filters: {},
            activeFilters: [],
            modal:false,
        }
    },
    mutations: {
        pushToData(state, item) {
            state.data.push(item)
        },
        popFromData(state, {item, comparator}) {
            const index = state.data.findIndex(d => d[comparator] === item[comparator]);
            state.data.splice(index, 0)
        },

        /**
         *
         * @param state
         * @param filterId
         */
        clearFilter(state, filterId) {
            const index = state.activeFilters.findIndex(filter => filter.id === filterId);
            state.activeFilters.splice(index, 1);
        },
        /**
         *
         * @param state
         */
        toggleModal(state,){
          state.modal = !state.modal
        },

        /**
         *
         * @param state
         */
        clearAllFilters(state) {
            state.activeFilters = [];
        },
        /**
         *
         * @param state
         * @param key
         */
        clearFiltersOfKey(state, key) {
            state.activeFilters.filter(af => af.key === key)
                .forEach(af => {
                    const index = state.activeFilters.findIndex(filter => filter.id === af.id);
                    state.activeFilters.splice(index, 1);
                })
        },
        /**
         *
         * @param state
         * @param filterKey
         * @param value
         * @param unique
         */
        addFilter(state, {filterKey, value, unique}) {
            let index = -1;
            if (unique === true) {
                index = state.activeFilters.findIndex(f => f.key === filterKey);
            } else {
                index = state.activeFilters.findIndex(f => f.value === value);
            }
            if (index === -1) {
                state.activeFilters.push(
                    {
                        key: filterKey,
                        id: Math.floor(Math.random() * 1000),
                        value: value
                    }
                )
            } else {
                state.activeFilters.splice(index, 1);
                if (unique === true) {
                    state.activeFilters.push(
                        {
                            key: filterKey,
                            id: Math.floor(Math.random() * 1000),
                            value: value
                        }
                    )
                }

            }
        },
        /**
         *
         * @param state
         * @param filterKey
         * @param values
         */
        addEmptyFiltersValues(state, {filterKey, values}) {
            values.forEach(f => {
                if (!state.filters[filterKey]) {
                    state.filters[filterKey].data.push({
                        alias: f,
                        value: f,
                        count: 0
                    })
                }
            })
        },
        /**
         *
         * @param state
         * @param data
         */
        setData(state, data) {
            state.data = data;
        },

        //type can be 'combobox','list','invisible'
        /**
         *
         * @param state
         * @param keys
         */
        createFilters(state, keys) {
            let filters = {};
            state.data.forEach(item => {
                keys.forEach(k => {
                    if (filters[k.key] === undefined) {
                        filters[k.key] = {
                            key: k.key,
                            data: [],
                            name: k.name,
                            type: k.type,
                            unique: k.unique,
                            alias: k.alias === undefined ? {} : k.alias,
                        }
                    }
                    const index = filters[k.key].data.findIndex(d => d.value === item[k.key]);
                    if (index === -1) {
                        let alias = filters[k.key].alias[item[k.key]] !== undefined ? filters[k.key].alias[item[k.key]] : null;
                        filters[k.key].data.push(
                            {
                                alias: alias,
                                value: item[k.key],
                                count: 1
                            }
                        )
                    } else {

                        filters[k.key].data[index].count++
                    }
                })
            });
            state.filters = filters;
        }
    },
    actions: {

        newDataEntry({commit, state}, data) {
            //todo
        },
        deleteDataEntry({commit,state},data){
            //todo
        },

        /**
         *
         * @param commit
         * @param state
         * @param key
         */
        clearFiltersOfKey({commit, state}, key) {
            state.activeFilters.filter(af => af.key === key)
                .forEach(af => commit('clearFilter', af.id))
        }
    },
    getters: {
        /**
         *
         * @param s
         * @returns {*}
         */
        modal:s=>s.modal,
        /**
         *
         * @param s
         * @returns {function(*): boolean}
         */
        isFilterItemActive: s => value => s.activeFilters.findIndex(f => f.value === value) !== -1,
        /**
         *
         * @param s
         * @returns {any}
         */
        activeFilters: s => s.activeFilters,
        /**
         *
         * @param s
         * @returns {number}
         */
        activeFiltersLength: s => s.activeFilters.length,
        /**
         *
         * @param s
         * @returns {Function[]}
         */
        filters: s => Object.values(s.filters),
        /**
         *
         * @param s
         * @returns {*}
         */
        data: s => {
            console.log('getter triggered');
            if (s.activeFilters.length > 0) {
                return s.data.filter(item => {
                        let results = [];
                        Object.values(s.filters).forEach(f => {
                            let filterItems = s.activeFilters.filter(af => af.key === f.key);
                            if (filterItems.length > 0) {
                                results.push(filterItems.some(filter => item[filter.key] === filter.value))
                            } else {
                                results.push(true)
                            }
                        });
                        console.log(results);
                        return results.every(r => r === true)
                    }
                )

            } else {
                return s.data
            }
        }

    }
})
