<template>
  <v-card class="mb-2" flat>
    <v-card-text>
      <v-combobox hide-details :items="[{filter:null,value:'Όλα', alias:null}, ...filter.data]" item-text="value"
                  v-model=selected :label="filter.name" @change="onChange()">
        <template v-slot:item="data">
            <v-list-item-content v-if="
                    data.item.value!== null ||
                    data.item.value !== undefined ||
                    data.item.value !==''"
            >
              <v-list-item-title> {{ filterItemText(data.item) }}</v-list-item-title>
            </v-list-item-content>
        </template>
      </v-combobox>
    </v-card-text>
  </v-card>

</template>

<script>
export default {
  name: "FilterComboBox",
  data: () => ({
    selected: null
  }),
  props: {
    filter: Object,
    isFilterItemActive: Function,
    addFilter: Function,
    clearFiltersOfKey: Function,
  },

  methods: {
    filterItemText(item) {
      return item.alias !== null ? item.alias : item.value
    },
    onChange() {
      if (this.selected.filter !== null) {
        this.addFilter({filterKey: this.filter.key, value: this.selected.value, unique: this.filter.unique})
      } else {
        this.clearFiltersOfKey(this.filter.key);
      }
    }
  }
}
</script>

<style scoped>

</style>
