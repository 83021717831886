<template>
  <v-app>
    <pwa-update></pwa-update>
    <topbar dense
            color="topbar"
            v-if="layout.menu"
            :show-sidebar-icon="layout.sidebar"
    >
      <template v-slot:after-actions>
        <sync-messages v-if="hasDb"></sync-messages>
      </template>
    </topbar>
    <sidebar
        active-color="primary lighten-3"
        dark
        v-if="layout.sidebar && userDetails.Subscription"
        color="primary"
        left
        fixed
    />
    <v-main class="background">
      <v-progress-linear fixed height="10" indeterminate value="100" v-if="loading"></v-progress-linear>
      <v-container fluid class="pt-lg-4 pb-lg-4 pl-lg-8 pr-lg-8 pl-sm-4 pr-sm-4" style="max-width: 1920px">
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>
0
<script>

import Topbar from "./wl-core/components/Topbar";
import Sidebar from "./wl-core/components/Sidebar";
import {mapGetters, mapMutations, mapState} from "vuex";
import Loading from "./wl-core/components/Loading";
import Error from "./wl-core/components/Error";
import SyncMessages from "@/components/SyncMessages";
import db from "@/wl-core/db/schema";
import appConfig from "@/config";
import store from "@/store";
import config from "@/config";
import PwaUpdate from "@/wl-core/components/PwaUpdate";

export default {
  name: 'App',

  components: {
    PwaUpdate,
    SyncMessages,
    Error,
    Loading,
    Sidebar,
    Topbar
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapState([
        'hasDb'
    ]),
    ...mapGetters([
      'error',
      'loading',
      'userDetails'
    ]),
    ...mapGetters('layout',
        [
          "userAgent",
          "layout"
        ]
    )
  },
  watch: {
    userDetails: function (val) {
      if (val.Subscription && val.AltTiSi.IsMobileUser && this.hasDb) {
        console.log(this.hasDb)
        db.open(appConfig.dbName).then(err => {
          store.dispatch('db/syncAll')
          store.dispatch('db/autoSync')
        })
      }
    },
    loading: function (val) {
      if (val) {
        this.$modal.show('loading')
      } else {
        this.$modal.hide('loading')
      }
    },
    error: function (val) {
      if (val) {
        this.$modal.show('error')
      } else {
        this.$modal.hide('error')
      }
    }
  },
  created() {
    this.checkMobile()
  },
  methods: {
    ...mapMutations('layout',
        ["setMobile"]
    ),
    checkMobile() {
      if (window.innerWidth <= 760) {
        this.setMobile(true)
      } else {
        this.setMobile(false)
      }
    },
  }


};
</script>
