<template>
    <div>
        <v-card>
            <v-card-title>
                Συντήρηση
            </v-card-title>
            <v-card-text>
                Συχνότητα συντήρησης
                <v-simple-table dense fixed-header>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">Ιαν</th>
                            <th class="text-left">Φεβ</th>
                            <th class="text-left">Μαρ</th>
                            <th class="text-left">Απρ</th>
                            <th class="text-left">Μάης</th>
                            <th class="text-left">Ιουν</th>
                            <th class="text-left">Ιουλ</th>
                            <th class="text-left">Αυγ</th>
                            <th class="text-left">Σεπτ</th>
                            <th class="text-left">Οκτ</th>
                            <th class="text-left">Φλεβ</th>
                            <th class="text-left">Δεκ</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td v-for="month in projectDetails.SixnotitaSintirisis">
                                <v-icon small v-if="month">mdi-check-circle</v-icon>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>

            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>{{projectDetails.LastSint | fullDate}}</v-list-item-title>
                        <v-list-item-subtitle> Τελευταία συντήρηση</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content>
                        <v-list-item-title>{{projectDetails.EktHmerSint | fullDate}}</v-list-item-title>
                        <v-list-item-subtitle> Επόμενη συντήρηση (εκτίμιση)</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
        <v-card class="mt-4">
            <v-card-title> Τεχνικά Χαρακτηριστικά</v-card-title>
            <v-card-text>
                <v-data-iterator
                        :group-by="['SpecDescr']"
                        :items="projectDetails.Specifications"
                        hide-default-footer
                        disable-pagination
                        no-data-text="Δεν υπάρχουν τεχνικά χαρακτηριστικά διαθέσιμα"
                >
                    <template v-slot:default="props">
                        <template >
                            <v-expansion-panels flat multiple :value="[0 ]"  >
                                <v-expansion-panel  v-for="group in props.groupedItems">
                                    <v-expansion-panel-header class="pa-0 grey lighten-3">
                                        <v-list-item dense >
                                            <v-list-item-content>
                                                <v-list-item-title class="text--black">
                                                    {{group.name}}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content eager>
                                        <v-list >
                                            <v-list-item class="pa-0"  v-for="item in group.items">
                                                <v-list-item-content>
                                                    <v-list-item-title> {{item.SpecItemValue}}</v-list-item-title>
                                                    <v-list-item-subtitle> {{item.SpecItemDescr}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>

                        </template>

                    </template>
                </v-data-iterator>
            </v-card-text>
        </v-card>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "Details",
        created() {
            this.getProjectDetails();
        },
        computed:{
          ...mapGetters('project',[
              "projectId",
              "projectDetails"
          ])
        },
        methods:{
          ...mapActions('project',[
              "getProjectDetails"
          ])
        }
    }
</script>

<style scoped>

</style>
