<template>
    <div>
        <portal to="toolbar-subtitle"> Εικόνες Ανελκυστήρα</portal>
        <v-card>
            <v-card-text>
                <media-library
                        :mobile-layout="isMobile"
                        :directories="tmimata"
                        :media="projectMedia"
                        media-directory-key="TmimaDescr"
                        media-url-key="Url"
                        @onDelete="deletePicture"
                />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import MediaLibrary from "../../wl-media-library/mediaLibrary";
    import {mapActions, mapGetters, mapState} from "vuex";

    export default {
        name: "Media",
        components: {MediaLibrary},
        data: () => ({}),
        created() {
            this.getProjectPictures()
        },
        computed: {
            ...mapGetters('project', [
                "projectMedia",
                "projectId"
            ]),
            ...mapGetters('layout', [
                "isMobile"
            ]),
            ...mapState('project', [
                "tmimata"
            ])
        },
        methods: {
            deletePicture(img) {
                this.deleteProjectPicture(img.FileName);
            },
            ...mapActions('project', [
                "getProjectPictures",
                "deleteProjectPicture"
            ])
        }

    }
</script>

<style scoped>

</style>
