export class IssueItem {
    constructor(obj) {
        this.Issue_ID = obj.Issue_ID;
        this.Action_ID = obj.Action_ID;
        this.ActionNotes = obj.ActionNotes;
        this.WhoAnswer_ID = obj.WhoAnswer_ID;
        this.DateInsert = obj.DateInsert;
        this.setSenter();
    }

    setSenter(){
        this.Senter = this.WhoAnswer_ID === 1? 'Εσείς':'Το γραφείο'
    }
}
