<template>
    <v-chip-group>
        <v-chip v-if="activeFilters.length>0" color="primary" @click="clearAllFilters()">
            Καθαρισμός
        </v-chip>
        <v-chip v-for="f in activeFilters">
            {{f.value}}
            <v-icon right @click="clearFilter(f.id)"> mdi-close</v-icon>
        </v-chip>
    </v-chip-group>
</template>

<script>
    import {mapMutations, mapGetters} from 'vuex';

    export default {
        name: "ActiveFilters",
        props: {moduleName: String},

        beforeCreate() {
            let namespace = this.$options.propsData.moduleName;
            this.$options.computed = {
                ...mapGetters(namespace, [
                    "activeFilters",
                ])
            };
            this.$options.methods = {
                ...mapMutations(namespace, [
                    "clearFilter",
                    "clearAllFilters"
                ])
            }
        },
    }
</script>

<style scoped>

</style>
