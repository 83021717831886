import {Error} from "./Error";
import {Office} from "./Office";
import {Allows} from "./Allows";

export default class UserDetails {
    constructor(obj) {
        this.Subscription = obj.Subscription;
        this.Username = obj.Username;
        this.User_ID = obj.User_ID;
        this.Contact_ID = obj.Contact_ID;
        this.Fullname = obj.Fullname;
        this.DateUpdated = obj.DateUpdated;
        this.EndOfLifeInSeconds = obj.EndOfLifeInSeconds;
        this.Token = obj.Token;
        this.IsFirstTime = obj.IsFirstTime;
        this.setOffice(obj.Office);
        this.setAllows(obj.Allows);
        this.AltTiSi = obj.AltTiSi;

    }

    setOffice(office) {

        if (office) {
            this.Office = new Office(office)
        } else {
            this.Office = null

        }
    }

    setAllows(allows) {
        if (allows) {
            this.Allows = new Allows(allows)
        }
    }
}

