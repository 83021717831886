import {IssueItem} from "./IssueItem";

export class Issue {
    constructor(obj) {
        this.Issue_ID = obj.Issue_ID;
        this.IssueTitle = obj.IssueTitle;
        this.IssueType_ID = obj.IssueType_ID;
        this.IssueTypeDesr = obj.IssueTypeDesr;
        this.DateCreated = obj.DateCreated;
        this.BranchAddress = obj.BranchAddress;
        this.Items = [];
        this.setIssueItems(obj.IssueItem);
        this.Finished = obj.Finished;
    }

    getLastResponse() {
        let index = this.Items.length -1;
        if (this.Items.length === 1 && this.Items[index].WhoAnswer_ID === 2 && !this.Items[index].ActionNotes) {

            return new IssueItem({
                Issue_ID: this.Items[index].Issue_ID,
                Action_ID: this.Items[index].Action_ID,
                ActionNotes: 'Δημιουργήσατε το αίτημα.',
                DateInsert: this.Items[index].DateInsert,
                WhoAnswer_ID: this.Items[index].WhoAnswer_ID
            })
        } else {
            return this.Items[index];
        }
    }

    setIssueItems(issueItems) {
        if (issueItems) {
            this.Items = issueItems.map(it => new IssueItem(it)).sort((a, b) => new Date(a.DateInsert) - new Date(b.DateInsert))
        } else {
            this.Items = []
        }
    }
}
