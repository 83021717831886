import  store from '../../../store'

export class DeptDetails {
    constructor(obj) {
        this.Partner_ID = obj.Partner_ID;
        this.Branch_ID = obj.Branch_ID;
        this.DateInvoice = obj.DateInvoice;
        this.NumberPar = obj.NumberPar;
        this.InvoiceDescr = obj.InvoiceDescr;
        this.Description = obj.Description;
        this.Balance = obj.Balance;
        this.StandBy = obj.StandBy;
        this.BranchAddress = null;
        this.BranchAreaName = null;
        this.PartnerBrandName = obj.PartnerBrandName;
        this.setBranch();
        this.setStatusName();
    }

    setStatusName(){
        this.StatusName = this.StandBy?'Σε αναμονή':'Εκδόθηκε'
    }
    setBranch(){
       const branch = store.getters['branch'](this.Branch_ID);
       if(branch){
           this.BranchAddress = branch.BranchAddress;
           this.BranchAreaName = branch.BranchAreaName;
           this.FullBranch = this.BranchAddress+', '+this.BranchAreaName;

       }
    }

}
