export default {
    sidebar: [
        {
            text: 'Αρχική Σελίδα',
            icon: 'mdi-home',
            to: "/preview"
        },
        {
            text: 'Πελάτες ',
            icon: 'mdi-briefcase',
            to:'/partners'
        },
        {
            to:'/maintenances',
            icon:'mdi-wrench',
            text:'Προγραμματισμός Συντηρήσεων'
        }
    ]
}
