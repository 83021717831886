import axios from '../axios';
import config from "../../../config";
import {BranchDetails} from "../classes/BranchDetails";

export default {
    getBranch(id) {
        return axios.get(config.host + '/branches/branchDetails', {
            params: {
                Branch_ID: id
            }
        }).then(resp => new BranchDetails(resp.data.Data))
    }
}
