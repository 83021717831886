export  class Allows {
    constructor(obj) {
        this.AddCallouts = obj.AddCallouts;
        this.ShowDepts = obj.ShowDepts;
        this.ShowMaintenances= obj.ShowMaintenances;
        this.ShowCallouts = obj.ShowCallouts;
        this.ShowTasks = obj.ShowTasks;
        this.ShowStartEnd = obj.ShowStartEnd;
        this.ShowPeriodic = obj.ShowPeriodic;
        this.InformArrive = obj.InformArrive;
        this.ShowSintirName = obj.ShowSintirName;
        this.ShowSintirPhone = obj.ShowSintirPhone;
        this.AddIssues = obj.AddIssues;
        this.ShowDiagnosiAction = obj.ShowDiagnosiAction;
        this.ShowRepresentative = obj.ShowRepresentative;
        this.ShowDeptsMoves = obj.ShowDeptsMoves;
        this.IsSuperVisor = obj.IsSuperVisor;

    }
}

