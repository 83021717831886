import UserDetails from "../api/classes/UserDetails";
import jsCookies from "js-cookie";
import api from "../api";
import XLSX from "xlsx";
import config from "@/config";

export default {
    state: {
        colors: {},
        userDetails: {},
        error: false,
        errorDetails: {},
        loading: false,
        loadingText: '',
        isMobile: false,
        showIframeModal: false,
        iframeLink: '',
        isOnline: true,
        hasDb:false,
    },
    actions: {
        exportToExcel({}, name) {
            let table = document.querySelector('#table table');
            let wb = XLSX.utils.table_to_book(table, {sheet: "Sheet JS"});
            XLSX.writeFile(wb, name + '.xlsx')
        },
        authenticateCookie({dispatch}) {
            jsCookies.remove('session');
            const cookie = jsCookies.get('auth');
            return dispatch('getSubscriptionViaToken', cookie)
        },
        getUserDetailsFromSession({commit}) {
            if (sessionStorage.getItem('session')) {
                let userDetails = JSON.parse(sessionStorage.getItem('session'));
                commit('setUserDetails', userDetails)
            }
        },
        getSubscriptionViaToken({state, commit}, token) {
            commit('showLoading')
            return api.login.getSubscriptionViaToken(token).then(
                userDetails => {
                    commit('setUserDetails', userDetails);
                    commit('setSessionCookie');
                    commit('hideLoading');
                    commit('setHasDb');
                    return Promise.resolve(state.userDetails)
                }).catch(err => {
                commit('showError')
                commit('hideLoading')
                commit('logout')
            })
                .finally(e => {
                    commit('hideLoading')
                })
        },
        getSubscriptionDetails({state, commit}, {username, password, rememberMe}) {
            commit('showLoading');
            return api.login.getSubscriptionDetails(username, password, rememberMe).then(
                userDetails => {
                    commit('setUserDetails', userDetails);
                    commit('setSessionCookie');
                    if (userDetails.Token) {
                        commit('setRememberMeCookie', userDetails.Token);
                    }
                    commit('hideLoading');
                    commit('setHasDb');
                    return Promise.resolve(state.userDetails)
                }).finally(e => {
                commit('hideLoading')
            })
        },

    },
    mutations: {
        setHasDb(state){
            if (config.hasDb !== undefined){
                state.hasDb = config.hasDb;
            }else{
                state.hasDb = false;
            }
        },
        setIsOnline(state, value) {
            state.isOnline = value
        },
        toggleIframeModal(state, {link}) {
            state.showIframeModal = !state.showIframeModal;
            if (state.showIframeModal) {
                state.iframeLink = link
            } else {
                state.iframeLink = null;
            }
        },
        setUserDetails(state, userDetails) {
            if (userDetails instanceof UserDetails) {
                state.userDetails = userDetails;
                state.colors = userDetails.Office.Colors;
            } else {
                state.userDetails = new UserDetails(userDetails);
                state.colors = userDetails.Office.Colors;
            }
        },
        setRememberMeCookie(state) {
            jsCookies.set('auth', state.userDetails.Token, {expires: 30})
        },
        setSessionCookie(state) {
            sessionStorage.setItem('session',JSON.stringify(state.userDetails))
        },

        logout(state) {
            state.userDetails = {};
            jsCookies.remove('auth');
            sessionStorage.removeItem('session')
        },
        setLoadingMessage(state, message) {
            state.loadingText = message
        },
        showError(state) {
            state.errorText = "Υπήρξε κάποιο πρόβλημα";
            state.error = true;
        },
        hideError(state,) {
            state.error = false;
        },
        showLoading(state, message) {
            state.loadingText = "Παρακαλώ περιμένετε";
            state.loading = true;
        },
        hideLoading(state) {
            state.loading = false
        },
        setMobile(state, isMobile) {
            state.isMobile = isMobile
        },
        setErrorDetails(state, error) {
            state.errorDetails = error;
        },

    },
    getters: {
        showIframeModal: s => s.showIframeModal,
        iframeLink: s => s.iframeLink,
        colors: s => s.colors,
        loading: s => s.loading,
        loadingText: s => s.loadingText,
        errorDetails: s => s.errorDetails,
        error: s => s.error,
        userDetails: s => s.userDetails,
        isMobile: s => s.isMobile,
        token: () => jsCookies.get('auth'),
        isOnline: s => s.isOnline

    }
}
