<template>
  <div>
    <v-menu>
      <template v-slot:activator="{on,attrs}">
        <v-btn
            class="mr-2"
            rounded
            outlined
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>mdi-reload</v-icon>
          <span v-if="isOnline"> {{ remainingTime }} </span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item :disabled="!isOnline" @click="syncAll(true)">
          <v-list-item-action-text>
            <v-icon>mdi-reload</v-icon>
          </v-list-item-action-text>
          <v-list-item-title>
            Ανανέωση Δεδομένων
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  <v-chip outlined :color="isOnline?'green':'red'">
    <v-icon v-if="isOnline">mdi-wifi</v-icon>
    <v-icon v-else>mdi-wifi-off</v-icon>
    {{isOnline?'Online':'Offline'}}
  </v-chip>
  </div>

</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "SyncMessages",
  computed: {
    ...mapGetters([
        "isOnline"
    ]),
    ...mapState('db', [
      "remainingTime"
    ])
  },
  methods: {
    ...mapActions('db', [
      "syncAll"
    ])
  }
}
</script>

<style scoped>

</style>
