import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import PortalVue from 'portal-vue'
import VModal from 'vue-js-modal'
import menu from './menu'
import {Filters} from "./wl-core/api/classes/Filters";
import appConfig from './config'
import Dexie from "dexie";
import db from './wl-core/db/schema'
import './registerServiceWorker'

Vue.config.productionTip = false;
Vue.use(PortalVue);
Vue.use(VModal, {dynamic: true, dynamicDefaults: {}});
Vue.filter('time', Filters.time);
Vue.filter('date', Filters.date);
Vue.filter('year', Filters.year);
Vue.filter('fullDate', Filters.fullDate);
Vue.filter('fixed', Filters.fixed);
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')

setInterval(()=>{store.commit('setIsOnline',navigator.onLine)},10000)
store.commit('layout/setSidebarItems', menu.sidebar);
