import Colors from "./Colors";
import TexnitesDetails from "@/wl-core/api/classes/TexnitesDetails";

export  class Office {
    constructor(obj) {
        this.BrandName = obj.BrandName;
        this.ID = obj.ID;
        this.PhoneNumber = obj.PhoneNumber;
        this.Email = obj.Email;
        this.PostCode = obj.PostCode;
        this.Address = obj.Address;
        this.AreaName = obj.AreaName;
        this.Longtitude = obj.Longtitude;
        this.Latitude = obj.Latitude;
        this.Ibans = obj.IBANs;
        this.Colors = new Colors(obj.Colors);
        this.Texnites = this.setTexnites(obj.Texnites)
    }
    setTexnites(texnites){
        if(texnites){
            return texnites.map(t=>new TexnitesDetails(t))
        }else{
            return []
        }
    }
}
