<template>
  <v-row>
    <v-col :lg="showFilters?'9':'12'" sm="12">

      <v-card class="ma-3">
        <v-toolbar color="transparent" flat dense v-if="!hideToolbar">
          <v-toolbar-title class="pr-4">
            <strong> {{ title }} </strong>
          </v-toolbar-title>
          <v-text-field v-if="!hideSearch && !isMobile" single-line hide-details  v-model="search"
                        :label="searchLabel"></v-text-field>
          <v-toolbar-items>
            <filters-modal v-if="filters && showFilterModal" :module-name="moduleName"></filters-modal>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-text-field dense v-if="!hideSearch && isMobile" single-line hide-details  v-model="search"
                        :label="searchLabel"></v-text-field>
        </v-card-text>
          <active-filters :module-name="moduleName"></active-filters>
          <slot name="desktop" v-if="!isMobile" v-bind:data="data" v-bind:search="search"></slot>
          <slot name="mobile" v-else v-bind:data="data" v-bind:search="search"></slot>
      </v-card>
    </v-col>
    <v-col lg="3" sm="12" v-if="!isMobile && showFilters">
      <filters :module-name="moduleName"></filters>
    </v-col>
  </v-row>

</template>

<script>
import FiltersModal from "@/wl-datagrid-handler/FiltersModal";
import store from "@/store";
import dataGridHandler from "@/wl-datagrid-handler/dataGridHandler";
import {mapGetters, mapMutations} from "vuex";
import Filters from "@/wl-datagrid-handler/Filters";
import ActiveFilters from "@/wl-datagrid-handler/ActiveFilters";

export default {
  name: "FilterIterator",
  components: {ActiveFilters, Filters, FiltersModal},
  data: () => ({
    search: null
  }),
  props: {
    filters: {type: Array, default: [], required: false},
    dataSet: {type: Array, default: [], required: true},
    disablePagination: {type: Boolean, default: null, required: false},
    isMobile: {type: Boolean, default: null, required: false},
    moduleName: {type: String, default: null, required: false},
    searchLabel: {type: String, default: null, required: false},
    title: {type: String, default: null, required: false},
    hideSearch: {type: Boolean, default: null, required: false},
    hideToolbar: {type: Boolean, default: null, required: false},
    showFilters:{type:Boolean, default:false},
    showFilterModal: {type: Boolean, default: null, required: false}
  },
  beforeCreate() {
    let namespace = this.$options.propsData.moduleName;
    store.unregisterModule(this.$options.propsData.moduleName);
    store.registerModule(this.$options.propsData.moduleName, dataGridHandler);
    this.$options.computed = {
      ...mapGetters(namespace, [
        "data"
      ]),
    };
    this.$options.methods = {
      ...mapMutations(namespace, [
        "setData",
        "createFilters",
      ])
    }

  },
  watch: {
    dataSet(dataset) {
      store.commit(this.moduleName + '/setData', dataset)
      if (this.$options.propsData.filters) {
        store.commit(this.moduleName + '/createFilters', this.filters)
      }
    }
  },
  beforeDestroy() {
    store.unregisterModule(this.$options.propsData.moduleName);
  },
  created() {
    store.commit(this.moduleName + '/setData', this.dataSet)
    if (this.$options.propsData.filters) {
      store.commit(this.moduleName + '/createFilters', this.filters)
    }
  },
}
</script>

<style scoped>

</style>
