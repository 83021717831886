<template>
  <div>
    <portal to="toolbar-title">
      Προγρ. Συντηρήσεων
    </portal>
    <portal to="toolbar-actions">
      <v-btn color="transparent" v-if="!layout.sidebar" @click="close()">
        <v-icon>mdi-chevron-left</v-icon> ΠΙΣΩ
      </v-btn>
    </portal>

    <v-dialog fullscreen v-model="showSchedulingModal">
      <v-card tile color="background">
        <v-toolbar>
          <v-toolbar-title>
            Ανελκυστήρες
          </v-toolbar-title>
          <v-spacer>
          </v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="showSchedulingModal=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <maintenance-scheduling v-if="selectedDayGroup" :day-group-id="selectedDayGroup" :zone-id="selectedZoneId"/>
      </v-card>
    </v-dialog>

    <v-dialog fullscreen v-model="showDayGroupModal">
      <v-card tile>
        <v-toolbar>
          <v-toolbar-title>
            {{ selectedZoneDescr }}
          </v-toolbar-title>
          <v-spacer>
          </v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="showDayGroupModal=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <zone-day-group-modal :zone-id="selectedZoneId" @dayGroupSelect="getDayGroupsClick"></zone-day-group-modal>
      </v-card>
    </v-dialog>

    <v-row dense>
      <v-col cols="12" lg="6">
        <v-card v-if="zones.length>0">
          <v-card-title> Ζώνες</v-card-title>
          <v-list>
            <v-list-item style="border-bottom: 1px solid #ffffff22;" v-for="zone in zones"
                         @click="getDayGroupClick(zone)">
              <v-list-item-content>
                <v-list-item-title>
                  {{ zone.ZoneDescr }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-progress-circular
                    :rotate="360"
                    :width="5"
                    size="50"
                    :value="completionPercent(zone)"
                    :color="zone.DoneMaint === zone.TotalMaint?'success':''"
                >
                  <span style="font-size: 0.7em"> {{ zone.DoneMaint }}/{{ zone.TotalMaint }} </span>

                </v-progress-circular>
              </v-list-item-action>
              <v-list-item-action>
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
        <v-card v-else>
          <v-card-title> Δρομολόγια</v-card-title>
          <v-list>
            <v-list-item style="border-bottom: 1px solid #ffffff22;" v-for="daygroup in dayGroups"
                         @click="getDayGroupsClick(daygroup.DayGroup)">
              <v-list-item-content>
                <v-list-item-title>
                  {{ daygroup.DayGroupName }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-progress-circular
                    :rotate="360"
                    :width="5"
                    size="50"
                    :value="completionPercent(daygroup)"
                    :color="daygroup.DoneMaint === daygroup.TotalMaint?'success':''"
                >
                  <span style="font-size: 0.7em"> {{ daygroup.DoneMaint }}/{{ daygroup.TotalMaint }} </span>

                </v-progress-circular>
              </v-list-item-action>
              <v-list-item-action>
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>

        </v-card>
        <v-card class="mt-3" flat color="transparent">
          <v-btn @click="getAllMaintsClick()" color="primary"> ΟΛΟΙ ΟΙ ΑΝΕΛΚΥΣΤΗΡΕΣ</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ZoneDayGroupModal from "@/views/Events/ZoneDayGroupModal";
import api from '@/wl-core/api'
import {mapGetters, mapMutations} from "vuex";
import MaintenanceScheduling from "@/views/Events/MaintenanceScheduling";
import backButton from "@/wl-core/mixins/backButton";

export default {
  name: "MaintainanceSchedulingHome",
  components: {MaintenanceScheduling, ZoneDayGroupModal},
  mixins: [backButton(['showSchedulingModal', 'showDayGroupModal'])],
  data: () => ({
    showDayGroupModal: false,
    showSchedulingModal: false,
    selectedZoneId: null,
    selectedZoneDescr: null,
    selectedDayGroup: null,
    dayGroups: [],
    zones: []
  }),
  created() {
    this.getZones()
  },
  computed: {
    ...mapGetters([
      "loading",
      "userDetails"
    ]),
    ...mapGetters('layout',[
        "layout"
    ])
  },
  methods: {
    ...mapMutations([
      "hideLoading",
      "showLoading"
    ]),
    getZones() {
      this.showLoading()
      api.projects.getProjectsZones()
          .then(zones => {
            this.hideLoading()
            this.zones = zones
            return Promise.resolve(zones)
          })
          .then(zones => {
            if (zones.length === 0) {
              return api.projects.getDayGroupsOfZone(-1)
                  .then(daygroups => this.dayGroups = daygroups)
            }
          })
          .finally
          (() => {
            this.hideLoading()
          })
    },
    completionPercent(dayGroup) {
      try {
        return (dayGroup.DoneMaint / dayGroup.TotalMaint) * 100
      } catch (e) {
        return 0
      }
    },
    getAllMaintsClick() {
      this.showSchedulingModal = true;
      this.selectedDayGroup = -1;
    },
    getDayGroupClick(zone) {
      this.selectedZoneId = zone.Zone_ID;
      this.selectedZoneDescr = zone.ZoneDescr
      this.showDayGroupModal = true;
    },
    getDayGroupsClick(id) {
      this.showSchedulingModal = true;
      this.selectedDayGroup = id;
    },
    close() {
      document.title ='Close'
    }
  }
}
</script>

<style scoped>

</style>
