import schema from './schema'
import config from "@/config";
import Partner from "@/wl-core/db/classes/Partner";
export  default {

    getPartnersDbSync(){
      return schema.Partners.orderBy('Sync_ID').reverse().limit(1).toArray().then(partners=>{
          if(partners.length > 0 ){
            return Promise.resolve(new Partner(partners[0]).Sync_ID)
          }else{
              return Promise.resolve(0)
          }
      })
    },
    putPartners(partners){
      return schema.Partners.bulkPut(partners).then(resp =>resp);
    },
    getPartners(){
       return  schema.table('Partners').toArray().then(partners=>Promise.resolve(partners))
    },

    getPartner(id){
    }
}
