import Vue from 'vue'
import Vuex from 'vuex'
import coreStore from "../wl-core/stores/coreStore";
import projectStore from "../wl-core/stores/projectStore";
import layoutStore from "../wl-core/stores/layoutStore";
import dbStore from "@/wl-core/stores/dbStore";
import config from "@/config";
import Dexie from "dexie";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {
        logout({commit}) {
            commit('logout')
            commit('db/clearTimer')
            if (config.hasDb) {
                Dexie.Delete(config.dbName)
            }
        }
    },
    modules: {
        db: dbStore,
        coreStore: coreStore,
        project: projectStore,
        layout: layoutStore,


    }
})
