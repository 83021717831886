import axios from '../axios';
import config from "../../../config";
import PartnerDetails from "../classes/PartnerDetails";
import EventDetails from "../classes/EventDetails";
import {ProjectsMaint} from "@/wl-core/api/classes/ProjectMaint";

export default {
    getEventDetails(params) {
        const parameters = params || {};
        return axios.get(config.host + '/events/EventsDetails', {
            params: parameters
        })
            .then(resp => {
                return resp.data.Data.map(p => new EventDetails(p));
            })
    },
    addCallout(callout) {
        return axios.post(config.host + '/events/AddNewCallout', {
            Partner_ID: callout.Partner_ID,
            Contact_ID: callout.Contact_ID,
            Branch_ID: callout.Branch_ID,
            Project_ID: callout.Project_ID,
            CalloutDescription: callout.CalloutDescription,
            Stopped: callout.Stopped || false,
            WhoAnnounced: callout.WhoAnnounced || "",
        }).then(resp => resp.data.Data)

    },
    addMaintenances(maintenances) {
        return axios.post(config.host+'/events/AddNewMaintenances',maintenances)
    },
    getMaintenanceSchedule({dateTo, dateFrom, dayGroup,zone}) {
        let extra = {}
        if (dayGroup || dayGroup === 0) {
            extra.dayGroup = dayGroup
        }
        if(dateFrom){
            extra.dateFrom = dateFrom
        }
        if(dateTo){
            extra.dateTo = dateTo
        }
        if(zone || zone === 0 ){
            extra.zone_ID = zone
        }
        return axios.get(config.host + '/events/cyclemaint', {
            params:extra

        }).then(resp => resp.data.Data.map(s => new ProjectsMaint(s)))
    }
}
