export default class EventDetails {

    constructor(obj) {
        this.Event_ID = obj.Event_ID;
        this.Description = obj.Description;
        this.EventType_ID = obj.EventType_ID;
        this.Partner_ID = obj.Partner_ID;
        this.Branch_ID = obj.Branch_ID;
        this.ProjectName = obj.ProjectName;
        this.DateEventInsert = obj.DateEventInsert;
        this.DateStart = obj.DateStart;
        this.DateEnd = obj.DateEnd;
        this.DateReaded = obj.DateReaded;
        this.DateTexnEnim = obj.DateTexnEnim;
        this.Diagnosi = obj.Diagnosi;
        this.Actions = obj.Actions;
        this.Status_ID = obj.Status_ID;
        this.Texnitis_ID = obj.Texnitis_ID;
        this.TexnitisName = obj.TexnitisName;
        this.TexnitisMobileNumber = obj.TexnitisMobileNumber;
        this.BranchAddress = obj.BranchAddress;
        this.BranchAreaName = obj.BranchAreaName;
        this.Notes = obj.Notes;
        this.setStatusName();
    }

    getColor() {
        switch (this.EventType_ID) {
            case 1:
                return 'maintenance';
            case 2:
                return 'callout';
            case 3:
                return 'task';

        }
    }


    setStatusName() {
        switch (this.Status_ID) {
            case 1:
                this.StatusName = 'Κλειστό';
                break;
            case 2:
                this.StatusName = 'Ανοιχτό';
                break;
            case 3:
                this.StatusName = 'Τρέχων';
                break;


        }
    }

    getCalendarStart(){
     return (this.EventType_ID === 1)?this.DateStart:this.DateEventInsert;
    }
    getLastDate() {
        if (this.DateReaded && !this.DateStart) {
            return this.DateReaded;
        } else if (this.DateReaded && this.DateStart) {
            return this.DateStart;
        } else {
            return this.DateEventInsert
        }
    }

    getEventStatus() {
        if (this.DateReaded && !this.DateStart) {
            return 'O τεχνικός είδε την κλήση'
        } else if (this.DateReaded && this.DateStart) {
            return 'Ξεκίνησε η επισκευή'
        } else {
            return 'Καταχωρήθηκε'
        }
    }
}
