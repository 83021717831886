import moment from "moment";
import Vue from "vue";

export const Filters = {
    time(value){
        if (!value) return '';
        value = value.toString();
        return moment(value).format('HH:mm')
    },
    date(value){
            if (!value) return '';
            value = value.toString();
            return moment(value).format('DD/MM')
    },
    year(value) {
        if (!value) return '';
        value = value.toString();
        return moment(value).format('YYYY')
    },
    fullDate(value) {
        if (!value) return '';
        value = value.toString();
        return moment(value).format('DD/MM/YY')
    },
    fixed(value) {
        let number = Number(value);
        return number.toFixed(2)
    }

};
