import axios from 'axios';
import store from '../../store'
import {Error} from "./classes/Error";
import jsCookies from "js-cookie";

const appConfig = require('../../config');


let host = appConfig.host;

let instance = axios.create({
    baseURL: host,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
});

instance.interceptors.request.use((config) => ({
    ...config,
    headers: {
        Subscription: store.getters['userDetails'].Subscription || "",
        ...config.headers
    }
}));

instance.interceptors.response.use(function (response) {
    const data = JSON.parse(response.data);
    data.Errors = new Error(data.Errors);
    response.data = data;
    if (data.Errors.Error_ID === 0) {
        return response
    }else if (data.Errors.Error_ID === 1010){
        const token = store.getters['token']
        if(token){
            store.dispatch('getSubscriptionViaToken',token).then(resp=>{
                location.reload();
            });
        }else{
            store.dispatch('logout');
        }
        return Promise.reject('Έχεται αποσυνδεθεί.')
    }
    else {
        return Promise.reject(data.Errors)
    }
}, function (error) {
    store.commit('hideLoading');
    store.commit('showError');
    store.commit('setErrorDetails',error );
    return Promise.reject(error);
});

export default instance;
