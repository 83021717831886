import axios from '../axios';
import config from "../../../config";
import PartnerDetails from "../classes/PartnerDetails";
import Partner from "@/wl-core/db/classes/Partner";
import db from '@/wl-core/db'
import Dexie from "dexie";


export default {

    getPartners({Sync_ID}) {
        if (!config.hasDb ||  Sync_ID !== null) {
            return axios.get(config.host + '/partners', {
                params: {
                    Sync_ID: Sync_ID
                }
            }).then(resp => {
                let partners = resp.data.Data.map(p => new Partner(p))
                if (config.hasDb) {
                    db.partners.putPartners(partners)
                }
                return Promise.resolve(partners)
            })
        } else {
          return   db.partners.getPartners().then(
                partners => partners.map(p => new Partner(p)))
        }
    }
    ,

    getPartnerDetails() {
        return axios.get(config.host + '/partners/PartnerDetails')
            .then(resp => {
                return resp.data.Data.map(p => new PartnerDetails(p));
            })
    }
}
