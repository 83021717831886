<template>
    <v-bottom-sheet :value="updateExists">
        <v-sheet
                class="text-center pa-4"
                height="120px"
        >
            Yπάρχει διαθέσιμη αναβάθμιση
            <div class="py-3">
                <v-btn text @click="refreshApp">
                    Update
                </v-btn>
            </div>
        </v-sheet>
    </v-bottom-sheet>
</template>

<script>
    import {mapGetters} from "vuex";
    import pwaUpdateMixin from "../mixins/pwaUpdateMixin";

    export default {
        name: "PwaUpdate",
        mixins: [pwaUpdateMixin],
    }
</script>

<style scoped>

</style>
