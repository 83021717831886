<template>
    <v-row class="fill-height" align="center"  justify="center">
        <v-card class="text-center pa-4" flat>
            <v-progress-circular
                    size="100"
                    indeterminate
                    color="primary"
            />
            </br>
            <span class="display-1"> {{loadingText}} </span>
        </v-card>

    </v-row>

</template>

<script>
    import {mapGetters} from 'vuex';
    export default {
        name: "Loading",
        computed:{
            ...mapGetters([
                "loadingText"
            ])
        }
    }
</script>

<style scoped>
</style>
