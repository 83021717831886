<template>
    <v-row class="fill-height" align="center" justify="center">
        <v-card class="text-center" flat v-if="errorDetails.response">
            <span class="display-3"> Error {{errorDetails.response.status}}</span>
            <p>
                Πρόβλημα με την λήψη δεδομένων από το url:
                {{errorDetails.response.request.responseURL}}
            </p>
            <v-btn color="primary" @click="hideError()">ΟΚ</v-btn>
        </v-card>
        <v-card flat v-else class=text-center>
            <span class="display-1">{{errorDetails}} </span>
            <p>
                Φαίνετε ότι υπάρχει πρόβλημα με την σύνδεση σας στο διαδύκτιο
            </p>
            <v-btn color="primary" @click="reload()">ΞΑΝΑΔΟΚΙΜΑΣΤΕ</v-btn>

        </v-card>
    </v-row>

</template>

<script>
    import {mapGetters, mapMutations} from "vuex";

    export default {
        name: "Error",
        computed: {

            ...mapGetters([
                "errorDetails"
            ])
        },
        methods: {
            reload(){
                window.location.reload();
            },
            ...mapMutations([
                "hideError"
            ]),
        }
    }
</script>

<style scoped>

</style>
