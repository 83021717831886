<template>
    <div>
        <v-dialog v-model="dialog" max-width="900px">
            <v-card v-if="img">
                <img :src="img[mediaUrlKey]" alt="εικόνα" width="100%">
                <v-toolbar>
                    <v-toolbar-title style="font-size: 0.9em">{{img[mediaUrlKey]}}</v-toolbar-title>
                    <v-spacer/>
                    <v-btn class="ml-3" @click="dialog = false"> Κλείσιμο</v-btn>
                    <v-btn class="ml-3" dark color="red"  @click="deleteImage(img)"> Διαγραφή</v-btn>
                </v-toolbar>
            </v-card>
        </v-dialog>
        <v-toolbar dense flat>
            <v-toolbar-title> Εικόνες</v-toolbar-title>
            <v-spacer/>
            <filters-modal v-if="mobileLayout" module-name="MediaLibraryHandler"/>
        </v-toolbar>
        <v-row >
            <v-col lg="9" md="9">
                <active-filters module-name="MediaLibraryHandler"/>
                <v-row>
                    <v-col lg="3" md="4" sm="6" v-for="img in data">
                        <v-card>
                            <v-img style="cursor: pointer" @click="openImage(img)" :src="img[mediaUrlKey]"/>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col lg="3" md="3" v-if="!mobileLayout">
                <filters module-name="MediaLibraryHandler"/>
            </v-col>
        </v-row>

    </div>

</template>

<script>
    import dataGridHandler from "../wl-datagrid-handler/dataGridHandler";
    import Filters from "../wl-datagrid-handler/Filters";
    import ActiveFilters from "../wl-datagrid-handler/ActiveFilters";
    import store from '../store';
    import {mapGetters, mapMutations} from "vuex";
    import FiltersModal from "../wl-datagrid-handler/FiltersModal";

    export default {
        name: "mediaLibrary",
        components: {ActiveFilters, FiltersModal, Filters},
        data: () => ({
            dialog: false,
            img: null,
        }),
        props: {
            mobileLayout: Boolean,
            media: Array,
            directories: Array,
            mediaUrlKey: String,
            mediaDirectoryKey: String,
        },
        beforeCreate() {
            // ...
            store.unregisterModule('MediaLibraryHandler');
            store.registerModule('MediaLibraryHandler', dataGridHandler);
        },
        beforeDestroy() {
            // ...
            store.unregisterModule('MediaLibraryHandler');
        },
        watch: {
            media(media) {
                this.setData(media);
                this.createFilters([{
                    key: this.mediaDirectoryKey,
                    name: 'Φακέλοι',
                    unique: true
                }]);
                this.addEmptyFiltersValues({
                    filterKey: this.mediaDirectoryKey,
                    values: this.directories.map(d => d.TmimaDescr),
                })
            }
        },
        created() {

        },
        computed: {
            ...mapGetters('MediaLibraryHandler', [
                "data"
            ]),
            ...mapGetters('layout',
                ["isMobile"])
        },
        methods: {
            deleteImage(img) {
                this.$emit('onDelete', img);
                this.dialog = false;
            },
            openImage(img) {
                this.dialog = true;
                this.img = img
            },
            ...mapMutations('MediaLibraryHandler', [
                "setData",
                "createFilters",
                "addEmptyFiltersValues"
            ]),
        }


    }
</script>

<style scoped>

</style>
