<template>
    <v-card>
        <v-data-table @click:row="rowClick" :headers="headers" :items="projectEvents" disable-pagination
                      hide-default-footer fixed-header height="600px">
            <template v-slot:item.DateEventInsert="{item}">
                <span v-if="item.EventType_ID === 1">{{item.DateStart | fullDate}}</span>
                <span v-else> {{item.DateEventInsert | fullDate}}</span>
            </template>
            <template v-slot:item.EventType_ID="{item}">
                <v-chip outlined v-if="item.EventType_ID === 1" color="maintenance"> Συντήρηση</v-chip>
                <v-chip outlined v-else-if="item.EventType_ID === 2" color="callout" dark> Βλάβη
                </v-chip>
                <v-chip outlined v-else-if="item.EventType_ID === 3" color="task" dark> Άλλη εργασία
                </v-chip>
            </template>
        </v-data-table>
    </v-card>

</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "Events",
        data:()=>({
            headers: [
                {
                    text: 'Hμ/νια ',
                    value: 'DateEventInsert',
                    filterable: true,
                    width: "100"
                },
                {
                    text: 'Tύπος',
                    value: 'EventType_ID',
                    filterable: true,
                    width: "100"
                },
                {
                    text: 'Περιγραφή',
                    value: 'Description',
                    filterable: true,
                },
            ]
        }),
        created() {
            this.getProjectEvents();
        },
        computed: {
            ...mapGetters('project', [
                "projectId",
                "projectEvents"
            ])
        },

        methods: {
            rowClick(event) {
                //this.$modal.show(EventCard, {eventId: event.Event_ID}, {height: 'auto', width: '70%'});
            },
            ...mapActions('project', [
                "getProjectEvents"
            ])

        }
    }
</script>

<style scoped>

</style>
