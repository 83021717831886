import {ProjectDetails} from "./ProjectDetails";

export class BranchDetails {
    constructor(obj) {
        this.Branch_ID = obj.Branch_ID;
        this.BranchCode = obj.BranchCode;
        this.BranchAddress = obj.BranchAddress;
        this.BranchAreaName = obj.BranchAreaName;
        this.BranchDimosName = obj.BranchDimosName;
        this.BranchLAT = obj.BranchLAT;
        this.BranchLONG =  obj.BranchLONG;
        this.Partner_ID = obj.Partner_ID;
        this.PartnerBrandName = obj.PartnerBrandName;
        this.PartnerFullName = null;
        this.PosoXreosis = obj.PosoXreosis;
        this.SixnotitaXreosis = obj.SixnotitaXreosis;
        this.ProjectCount = obj.ProjectCount;
        this.Representative = obj.Representative;
        this.setProjects(obj.Projects)
    }

    setProjects(projects){
        this.Projects = projects.map(pr=> new ProjectDetails(pr));
        this.ProjectCount = projects.length;
    }

}
