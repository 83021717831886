import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
import jsCookies from 'js-cookie'
import routerHelper from '../wl-core/helpers/router'
import Media from "../views/Project/Media";
import Project from "../views/Project/Project";
import Details from "../views/Project/Details";
import Events from "../views/Project/Events";
import Partners from "@/views/Partners/Partners";
import MaintenanceScheduling from "@/views/Events/MaintenanceSchedulingHome";
import MaintenanceSchedulingHome from "@/views/Events/MaintenanceSchedulingHome";


Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {requiresAuth: true, noBack: true},
    },
    {
        path: '/partners',
        component: Partners,
        meta: {requiresAuth: true,},
    },
    {
        path: '/project/:id',
        component: Project,
        meta: {requiresAuth: true,},
        children: [
            {
                path: 'media',
                name: 'Project Media',
                component: Media,
                meta: {requiresAuth: true,},
            },
            {
                path: 'details',
                name: 'Project Details',
                component: Details,
                meta: {requiresAuth: true,},
            },
            {
                path: 'events',
                name: 'Project Events',
                component: Events,
                meta: {requiresAuth: true,},
            }
        ]
    },
    {
        path: '/maintenances/',
        name: 'maintenances',
        meta: {requiresAuth: true},
         beforeEnter: (to, from, next) => {
            next('/maintenances/home')
        }
    },
    {
        path: '/maintenances/home',
        name: 'maintenances Home',
        meta: {requiresAuth: true},
        component: MaintenanceSchedulingHome,
    },
    {
        path: '/login',
        name: 'login',
        meta: {requiresAuth: false, noMenu: true},
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        //         // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "login" */ '../views/Login')
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
];

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    routerHelper.beforeEach(to, from, next);
});

export default router
