import {BranchDetails} from "./BranchDetails";

export default class PartnerDetails {
    constructor(obj) {
        this.Partner_ID = obj.Partner_ID;
        this.PartnerBrandName = obj.PartnerBrandName;
        this.PartnerInvAddress = obj.PartnerInvAddress;
        this.PartnerFullName = obj.PartnerBrandName+' - '+obj.PartnerInvAddress;
        this.setBranches(obj.Branches)
    }
    setBranches(branches){
        if(branches){
            this.Branches = branches.map(b=> new BranchDetails(b))
        }
    }
}
