export default class PictureList {
    constructor(obj) {
        this.Tmima_ID = obj.Tmima_ID;
        this.TmimaDescr = obj.TmimaDescr;
        this.Url = obj.URL;
        this.FileName = obj.URL.split('/').slice(-1)[0]

    }
}

