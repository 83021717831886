import axios from '../axios';
import config from "../../../config";
import PartnerDetails from "../classes/PartnerDetails";
import EventDetails from "../classes/EventDetails";
import {DeptDetails} from "../classes/DeptDetails";
import DeptMoveDetails from "../classes/DeptMoveDetails";

export default {
    /**
     *
     * @param params
     * @returns {Promise<AxiosResponse<T>>}
     */
    getDeptDetails(params) {
        const parameters = params || {};
        return axios.get(config.host + '/Financial/DeptsDetails', {
            params: parameters
        })
            .then(resp => {
                return resp.data.Data.map(p => new DeptDetails(p));
            })
    },
    /**
     *
     * @param params
     * @returns {Promise<AxiosResponse<T>>}
     */
    getDeptMoveDetails(params){
        const parameters = params || {};
        return axios.get(config.host+'/Financial/DeptsMoveDetails',{
            params:parameters
        })
            .then(resp=>{
                return resp.data.Data.map(p => new DeptMoveDetails(p))
            })
    }
}
