import {Specification} from "./Specification";

export class ProjectDetails {
    constructor(obj) {
        this.Project_ID = obj.Project_ID;
        this.ProjectCode = obj.ProjectCode;
        this.ProjectName = obj.ProjectName;
        this.SixnotitaSintirisis = obj.SixnotitaSintirisis;
        this.EktHmerSint = obj.EktHmerSint;
        this.LastSint = obj.LastSint;
        this.Specifications = [];
        this.setSpecifications(obj.Specifications)
    }

    setSpecifications(specifications) {
        if(specifications){
            this.Specifications = specifications.map(s => new Specification(s))
        }else{
            this.Specifications = []
        }
    }
}
