<template>
    <v-dialog persistent :value="modal" max-width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                    color="primary"
                    dark
                    icon
                    @click="toggleModal()"
            >
                <v-icon> mdi-filter-menu </v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title> Φίλτρα</v-toolbar-title>
                <v-spacer/>
                <v-btn fab small @click="toggleModal()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text style="max-height: 80vh; overflow-y: auto">
                <slot name="before-filters"/>
                <filters :module-name="moduleName"/>
                <slot name="after-filters"/>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import Filters from "./Filters";
    import ActiveFilters from "./ActiveFilters";
    import {mapGetters, mapMutations} from "vuex";

    export default {
        name: "FiltersModal",
        components: {ActiveFilters, Filters},
        props: {moduleName: String},
        beforeCreate() {
            let namespace = this.$options.propsData.moduleName;
            this.$options.computed = {
                ...mapGetters(namespace, [
                    "modal",
                ]),
            };
            this.$options.methods = {
                ...mapMutations(namespace, [
                    "toggleModal",
                ])
            }
        },
    }
</script>

<style scoped>

</style>
