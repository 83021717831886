import axios from '../axios';
import config from "../../../config";
import {Issue} from "../classes/Issue";
import {IssueType} from "../classes/IssueType";

export default {
    getIssues(params) {
        const parameters = params || {};
        return axios.get(config.host + '/issues/GetIssues', {params: parameters})
            .then(resp => {
                return resp.data.Data.map(i => new Issue(i))
            })
    },
    getIssueTypes() {
        return axios.get(config.host + '/issues/IssuesTypes')
            .then(resp=>{
                return resp.data.Data.map(it=>new IssueType(it))
            })
    },
    replyToIssue(reply){
      return axios.post(config.host+'/issues/ReplyIssue',{
          Action_ID: reply.Action_ID,
          Issue_ID: reply.Issue_ID,
          ActionNotes:reply.ActionNotes,
      })
    },
    addIssue(issue){
        return axios.post(config.host + '/issues/AddNewIssue',{
            Partner_ID:issue.Partner_ID,
            Project_ID: issue.Project_ID,
            Contact_ID: issue.Contact_ID,
            Description: issue.Description,
            IssueType_ID: issue.IssueType_ID,
            Action_ID: issue.Action_ID,
            PartnerNotes: issue.PartnerNotes,
            NextAction_ID: issue.NextAction_ID
        })
    }
}
