<template>
  <div v-if="!loading" style="padding-bottom: 70px;">
    <v-toolbar style="position:sticky; top:0; z-index: 100" flat>
      <v-text-field label="Αναζήτηση" hide-details v-model="search"></v-text-field>
      <v-spacer></v-spacer>
      <filters-modal module-name="Maintenances"></filters-modal>
    </v-toolbar>
    <active-filters module-name="Maintenances"></active-filters>
    <v-dialog overlay-color="white"  v-model="scheduleModal">
      <v-card>
        <v-card-title> Προγραμματισμός <v-spacer></v-spacer> <v-btn icon @click="scheduleModal=false"><v-icon> mdi-close</v-icon> </v-btn></v-card-title>
        <v-card-text>
          <v-form>
            <label> Ημερομηνία</label>
            <br>
            <input style="color:white; border-bottom: 1px solid white;" type="date"  v-model="texnEnim" :max="maxTexnEnim" :min="minTexnEnim">
            <v-select hide-details :items="userDetails.Office.Texnites"
                            :disabled="!userDetails.Allows.IsSuperVisor"
                            v-model="texnitisId"
                            item-value="Texnitis_ID"
                            item-text="TexnitisName"
                            label="Tεχνικός">

            </v-select>
          </v-form>
        </v-card-text>
        <v-expansion-panels accordion>
          <v-expansion-panel >
            <v-expansion-panel-header> Παρατηρήσεις </v-expansion-panel-header>
            <v-expansion-panel-content >
              <v-list dense>
                <v-list-item v-for="maint in selected">
                  <v-list-item-content>
                    <v-list-item-title> {{maint.BranchAddress}} </v-list-item-title>
                    <v-list-item-subtitle> {{maint.ProjectName}} </v-list-item-subtitle>
                    <v-text-field outlined label="παρατηρήσεις" dense hide-details v-model="maint.Notes"></v-text-field>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card-actions>
          <v-btn color="success" @click="addMaintenances()"> Αποθήκευση</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="ma-3" v-if="data">
      <v-card-title> <v-btn color="primary lighten-2" class="mr-3" fab outlined x-small icon @click="addAllForThisMonthToSelected()"> <v-icon small> mdi-check-all </v-icon> </v-btn> Αυτόν τον μήνα ({{ maintsForThisMonth.length }})</v-card-title>
      <v-data-iterator :items="maintsForThisMonth" :hide-default-footer="data.length< 50" :disable-pagination="data.length< 50" :search="search"
                       no-data-text="Δεν βρέθηκαν αποτελέσματα"
                       no-results-text="Δεν βρέθηκαν αποτελέσματα"
      >
        <template v-slot:default="props">
          <v-expansion-panels accordion>
            <project-maint-item @selected="addToSelected"
                                :isSelected="selected.findIndex(pr=>pr.Project_ID === maint.Project_ID) >-1"
                                v-for="maint in props.items" :project-maint="maint"></project-maint-item>
          </v-expansion-panels>
        </template>
      </v-data-iterator>

    </v-card>
    <v-card class="ma-3">
      <v-card-title> <v-btn color="primary lighten-2" class="mr-3" fab outlined x-small  @click="addAllNotForThisMonthToSelected()"> <v-icon> mdi-check-all </v-icon> </v-btn> Όχι αυτόν τον μήνα ({{ maintsNotThisMonth.length }})</v-card-title>
      <v-data-iterator :items="maintsNotThisMonth" :hide-default-footer="data.length< 50" :disable-pagination="data.length< 50" :search="search"
                       no-data-text="Δεν βρέθηκαν αποτελέσματα"
                       no-results-text="Δεν βρέθηκαν αποτελέσματα"
      >
        <template v-slot:default="props">
          <v-expansion-panels accordion>
            <project-maint-item @selected="addToSelected"
                                :isSelected="selected.findIndex(pr=>pr.Project_ID === maint.Project_ID) >-1"
                                v-for="maint in props.items" :project-maint="maint"></project-maint-item>
          </v-expansion-panels>
        </template>
      </v-data-iterator>
    </v-card>
    <v-footer fixed bottom v-if="selected.length > 0 ">
      <v-row>
        <v-col>
          Επιλέχθηκαν {{ selected.length }}
        </v-col>
        <v-col>
          <v-btn color="success" @click="scheduleModal=true;"> Προγραμματισμός</v-btn>
        </v-col>
      </v-row>
      <v-spacer>
      </v-spacer>
    </v-footer>
  </div>
  <v-skeleton-loader type="list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line" v-else>
  </v-skeleton-loader>
</template>

<script>
import api from '@/wl-core/api'
import moment from "moment";
import store from '@/store';
import {mapGetters, mapMutations} from "vuex";
import ProjectMaintItem from "@/views/Events/ProjectMaintItem";
import dataGridHandler from "@/wl-datagrid-handler/dataGridHandler";
import FiltersModal from "@/wl-datagrid-handler/FiltersModal";
import ActiveFilters from "@/wl-datagrid-handler/ActiveFilters";


export default {
  name: "MaintenanceScheduling",
  components: {ActiveFilters, FiltersModal, ProjectMaintItem},
  props: {
    dayGroupId: {type: Number, default: null, required: false},
    zoneId:{type:Number, default:null, required:false}
  },
  data: () => ({
    search: null,
    scheduleModal: false,
    texnitisId: 0,
    texnEnim: moment().format('YYYY-MM-DD'),
    minTexnEnim: moment().format('YYYY-MM-DD'),
    maxTexnEnim: moment().endOf('month').format('YYYY-MM-DD'),
    projectMaints: [],
    monthNow: moment().format('MMMM'),
    selected: [],
    notes: ''
  }),
  created() {
    this.texnitisId = this.userDetails.User_ID;
    store.unregisterModule('Maintenances');
    store.registerModule('Maintenances', dataGridHandler);
    this.getProjectMaints();
  },
  filters: {
    formatDate(value) {
      return moment(value).format('DD/MM/YY')
    }
  },
  watch: {
    dayGroupId() {
      this.getProjectMaints()
      this.selected = [];
    }
  },
  computed: {

    maintsForThisMonth() {
      return this.data.filter(p => p.hasMaintenanceThisMonth())
    },
    maintsNotThisMonth() {
      return this.data.filter(p => !p.hasMaintenanceThisMonth())
    },
    ...mapGetters('Maintenances', [
      "data"
    ]),

    ...mapGetters([
      "loading",
      "userDetails",
      "isMobile"
    ])
  },

  methods: {
    ...mapMutations('Maintenances', [
      "setData",
      "createFilters",
    ]),
    ...mapMutations([
      "showLoading",
      "hideLoading"
    ]),

    addMaintenances() {
      let maintenances = this.selected.map(p => ({
            Partner_ID: p.Partner_ID,
            Branch_ID: p.Branch_ID,
            Project_ID: p.Project_ID,
            DateTexnEnim: this.texnEnim,
            Texnitis_ID: this.texnitisId,
            User_ID: this.userDetails.User_ID,
            Notes: p.Notes,
          })
      )
      this.showLoading();
      api.events.addMaintenances(maintenances).then(resp => {
        this.hideLoading();
        this.scheduleModal = false;
        this.getProjectMaints();
        this.selected = [];
        document.title = 'closeAndSync'
      }).catch(err => {

      }).finally(resp => {
        this.hideLoading();
      })

    },
    stopPropagation(event) {
      event.stopPropagation()
    },
    addAllForThisMonthToSelected(){
       this.maintsForThisMonth.forEach(m=>{
        if(m.canEdit()) this.addToSelected(m);
      })
    },
    addAllNotForThisMonthToSelected() {
      this.maintsNotThisMonth.forEach(m=>{
        if(m.canEdit()) this.addToSelected(m);
      })
    },
    addToSelected(projectMaint) {
      let index = this.selected.findIndex(pr => projectMaint.Project_ID === pr.Project_ID)
      if (index === -1) {
        this.selected.push({Notes: '', ...projectMaint})
      } else {
        this.selected.splice(index, 1)
      }
    },
    getProjectMaints() {
      this.search = ""
      this.showLoading();
      api.events.getMaintenanceSchedule({
            dayGroup: this.dayGroupId,
            zone:this.zoneId

          }
      ).then(proejctMaints => {
        this.projectMaints = proejctMaints
        this.setData(proejctMaints);
        this.createFilters([

          {key: 'TexnitisName', name: 'Ανατεθημένα στον Τεχνικό'},
          {key: 'problem', name: 'Πρόβλημα Συντήρησης'},
          {key: 'SinPerYear', name: 'Συντηρήσεις τον χρόνο'},
          {key: 'ZoneDescr', name: 'Ζώνη'},
        ])
      }).finally(() => {
        this.hideLoading();
      })
    },

  }
}
</script>

<style scoped>

</style>
