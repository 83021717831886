import PartnerDetails from "@/wl-core/api/classes/PartnerDetails";

export default class Partner extends PartnerDetails{
    constructor(obj){
        super(obj);
        this.PartnerCode = obj.PartnerCode;
        this.PartnerTaxOffice = obj.PartnerTaxOffice;
        this.PartnerArea_ID = obj.PartnerArea_ID;
        this.PartnerAreaName = obj.PartnerAreaName;
        this.PartnerDimos_ID = obj.PartnerDimos_ID;
        this.PartnerJob_ID = obj.PartnerJob_ID;
        this.PartnerJobName = obj.PartnerJobName;
        this.PartnerNotes = obj.PartnerNotes;
        this.PartnerPostCode = obj.PartnerPostCode;
        this.PartnerTypeSale = obj.PartnerTypeSale;
        this.PartnerTypeSale_ID = obj.PartnerTypeSale_ID;
        this.InvType_ID = obj.InvType_ID;
        this.Taxes_ID = obj.Taxes_ID;
        this.TaxDescr = obj.TaxDescr;
        this.TaxPercent = obj.TaxPercent;
        this.PartnerLost = obj.PartnerLost;
        this.PartnerCode02 = obj.PartnerCode02;
        this.DateInsert = obj.DateInsert;
        this.DateUpdate = obj.DateUpdate;
        this.ParKraPercent = obj.ParKraPercent;
        this.DefPayWay_ID = obj.DefPayWay_ID;
        this.PayWayDescr = obj.PayWayDescr;
        this.DiscountPos = obj.DiscountPos;
        this.DayPist = obj.DayPist;
        this.MovesPerBranch = obj.MovesPerBranch;
        this.NoAddDiaxName = obj.NoAddDiaxName;
        this.SpecialCodes = obj.SpecialCodes;
        this.Sync_ID = obj.Sync_ID;
        this.PartnerMovesNotes = obj.PartnerMovesNotes;
        this.CustomerType_ID = obj.CustomerType_ID;
        this.PropNoShowBalance = obj.PropNoShowBalance;
    }
}
