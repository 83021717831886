import login from './sectors/login'
import partners from './sectors/partners'
import events from "./sectors/events";
import depts from "./sectors/depts";
import issues from "./sectors/issues";
import projects from "./sectors/projects";
import branches from "./sectors/branches"
import lists from "@/wl-core/api/sectors/lists";

export default {
    login:login,
    partners:partners,
    events:events,
    depts:depts,
    issues:issues,
    projects:projects,
    branches:branches,
    lists:lists
}

