<template>
  <v-expansion-panel>
    <v-expansion-panel-header :style="'background-color:'+(projectMaint.getColor())+'; padding:0;'">
      <v-list-item>
        <v-list-item-action class="justify-center">

          <v-badge v-if="!isMaintenanceDone && hasMoreMaintenances" color="primary" overlap top
                   :content="Number(projectMaint.DoneMaint)+'/'+projectMaint.maintenancesPerMonth()">
            <v-icon color="success">
              mdi-check-circle
            </v-icon>
          </v-badge>
          <v-icon color="success" v-if="isMaintenanceDone">
            mdi-check-circle
          </v-icon>
          <v-icon color="info" v-if="projectMaint.DateTexnEnim">
            mdi-calendar
          </v-icon>
          <v-icon v-if="projectMaint.DateTexnEnim">
          </v-icon>
          <v-checkbox v-if="projectMaint.canEdit()"
                      @click="$emit('selected',projectMaint)"
                      @click.stop="stopPropagation"
                      :value="isSelected"/>
        </v-list-item-action>
        <v-list-item-content>
          {{ projectMaint.BranchAddress }}
          <v-list-item-subtitle>{{ projectMaint.ProjectName }} {{projectMaint.PartnerBrandName}}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="projectMaint.DateSintir">
            <v-icon small v-if="hasSkippedMaintenance" color="warning">
              mdi-alert
            </v-icon>
            Τελευταία συντήρηση:
            <span :style="'color:'+(hasSkippedMaintenance?'orange':'')">
              {{ projectMaint.DateSintir | formatDate }}
            </span>
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="projectMaint.DateTexnEnim">
            <v-icon small v-if="scheduledMaintenanceNotDone" color="warning">
              mdi-alert
            </v-icon>
            Προγραμματίστικε για:
            <span :style="'color:'+(scheduledMaintenanceNotDone?'orange':'')">
              {{ projectMaint.DateTexnEnim | formatDate }}
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-expansion-panel-header>
    <v-expansion-panel-content color="background" class="pt-3">

      <div v-if="projectMaint.Omades.length" class="mb-3">
        <h3 class="mb-2"> Ομάδες:</h3>
        <v-card>
          <v-list dense>
            <v-list-item dense v-for="omada in projectMaint.Omades">
              <v-list-item-content>
                <v-list-item-title>
                  {{ omada.OmadaDescr}}
                </v-list-item-title>
                <span class="subtitle"> {{ omada.Value}} </span>
              </v-list-item-content>
            </v-list-item>

          </v-list>
        </v-card>
      </div>

      <h3> Συντήρησεις: {{ projectMaint.SinPerYear }} τον χρόνο</h3>
      <v-row>

        <v-col lg="12" cols="12">
          <v-alert type="warning"  colored-border border="left" dense v-if="projectMaint.problem==='Με πρόβλημα'">
            <span v-if="hasSkippedMaintenance" style="font-size: 0.9em">
              Δεν έχει πραγματοποιηθεί κάποιος από τους τελευταίους μήνες συντήρησης.
            </span>
            <span v-else-if="scheduledMaintenanceNotDone" style="font-size: 0.9em" >
              Η προγραμματισμένη συντήρηση δεν έγινε.
            </span>
          </v-alert>
          <v-alert type="info" colored-border border="left" dense v-if="hasMultipleSinterPerMonth">
            <span style="font-size: 0.9em"  >
              Πολλαπλές συντηρήσεις τον μήνα. Έγιναν {{Number(projectMaint.DoneMaint)}} / {{projectMaint.maintenancesPerMonth()}}
              τον μήνα αυτό
            </span>
          </v-alert>
          <v-simple-table  dense fixed-header >
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-center">1</th>
                <th class="text-center">2</th>
                <th class="text-center">3</th>
                <th class="text-center">4</th>
                <th class="text-center">5</th>
                <th class="text-center">6</th>
                <th class="text-center">7</th>
                <th class="text-center">8</th>
                <th class="text-center">9</th>
                <th class="text-center">10</th>
                <th class="text-center">11</th>
                <th class="text-center">12</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="text-center" v-for="(month,index) in projectMaint.SixnotitaSintirisis">
                  <v-icon :color="
                  (Number(monthNow) > Number(index.substring(1)))
                  &&(Number(index.substring(1)) >= Number(lastMaintenanceMonth))
                  && hasSkippedMaintenance? 'orange':''"
                          x-small
                          v-if="month">mdi-check-circle
                  </v-icon>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-data-table v-if="projectMaint.ExtraInfo.length" :items="projectMaint.ExtraInfo" hide-default-footer :headers="[]"> </v-data-table>
        </v-col>
        <v-col cols="6" lg="6">
          <v-card flat>
            <v-list dense>
              <v-list-item v-if="projectMaint.DateSintir">
                <v-list-item-content>
                  <v-list-item-title> Τελευταία:</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ projectMaint.DateSintir | formatDate }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="projectMaint.LastTexnitisName">
                <v-list-item-content>
                  <v-list-item-title> Τεχνικός:</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ projectMaint.LastTexnitisName }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="6" lg="6">
          <v-card flat>
            <v-list dense>
              <v-list-item v-if="projectMaint.EktHmerSint">
                <v-list-item-content>
                  <v-list-item-title> Εκτιμώμενη:</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ projectMaint.EktHmerSint | formatDate }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="projectMaint.DateTexnEnim">
                <v-list-item-content>
                  <v-list-item-title> Προγραμματίστικε:</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ projectMaint.DateTexnEnim | formatDate }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ projectMaint.TexnitisName }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import {ProjectsMaint} from "@/wl-core/api/classes/ProjectMaint";
import moment from "moment";

export default {
  data: () => ({
    monthNow: moment().format('MM'),
    //vuetify v-data-table headers for extra info
    extraInfoHeaders:[
      {text: 'Περιγραφή', value: 'Description'}
    ]
  }),
  name: "ProjectMaintItem",
  props: {
    projectMaint: {type: ProjectsMaint, default: null, required: false},
    isSelected: {type: Boolean, default: null, required: false}
  },
  filters: {
    formatDate(value) {
      return moment(value).format('DD/MM/YY')
    }
  },
  computed: {
    hasMoreMaintenances(){
      return this.projectMaint.DoneMaint >= 1 && this.projectMaint.DoneMaint < this.projectMaint.maintenancesPerMonth();
    },
    isMaintenanceDone() {
      return this.projectMaint.isMaintenanceDoneThisMonth()
    },
    hasMultipleSinterPerMonth() {
      return this.projectMaint.hasMultipleSintirPerMonth();
    },
    hasSkippedMaintenance() {
      return this.projectMaint.hasSkippedMaintenance();
    },
    scheduledMaintenanceNotDone() {
      return this.projectMaint.scheduledMaintenanceNotDone();
    },
    lastMaintenanceMonth() {
      return this.projectMaint.lastMaintenanceMonth();
    }
  },
  methods: {
    stopPropagation(event) {
      event.stopPropagation()
    },
  }
}
</script>

<style scoped>
.subtitle {
  font-size: 0.8em;
  color: #b3b3b3;
}
.v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td {
  padding: 0 4px !important;
}
</style>
