export default class Colors{
    constructor(obj) {
        this.SidebarColor = obj.SidebarColor || 'secondary';
        this.IsSidebarDark = obj.IsSidebarDark;
        this.SidebarIconsColor = obj.SidebarIconsColor || 'white';
        this.AppbarColor = obj.AppbarColor || 'primary';
        this.IsAppbarDark = obj.IsAppbarDark;
        this.BrandBackgroundColor = obj.BrandBackgroundColor||'white';
    }
}
