<template>
    <div>
        <div v-for="f in filters">
            <filter-list v-if="!f.type || f.type === 'list'"
                         :filter="f"
                         :add-filter="addFilter"
                         :is-filter-item-active="isFilterItemActive"
                         :clear-filters-of-key="clearFiltersOfKey"
            />
            <filter-combo-box v-if="f.type === 'combobox'"
                              :filter="f"
                              :add-filter="addFilter"
                              :is-filter-item-active="isFilterItemActive"
                              :clear-filters-of-key="clearFiltersOfKey"
            />
        </div>
    </div>
</template>

<script>
    import {mapMutations, mapGetters, mapActions} from 'vuex';
    import FilterList from "./FilterList";
    import FilterComboBox from "./FilterComboBox";

    export default {
        name: "Filters",
        components: {FilterComboBox, FilterList},
        props: {moduleName: String},
        data: () => ({}),
        beforeCreate() {
            let namespace = this.$options.propsData.moduleName;
            this.$options.computed = {
                ...mapGetters(namespace, [
                    "filters",
                    "isFilterItemActive",
                ]),
            };
            this.$options.methods = {
                ...mapMutations(namespace, [
                    "addFilter",
                    "clearFiltersOfKey"
                ])
            }
        },

    }
</script>

<style scoped>

</style>
