import api from '../api'

export default {
    namespaced: true,
    state: {
        projectId: null,
        projectDetails: null,
        projectMedia: [],
        projectEvents:[],
        tmimata: [],
    },
    actions: {
        getProjectEvents({commit,state}){
            if(state.projectEvents.length){
                return Promise.resolve(state.projectEvents)
            }else{
                commit('showLoading',null,{root:true});
                return api.events.getEventDetails({
                    Project_ID:state.projectId,
                })
                    .then(resp => {
                        commit('hideLoading',null,{root:true});
                        let sortedEvents = resp.sort((a,b)=>new Date(b.DateEventInsert) - new Date(a.DateEventInsert));
                        commit('setProjectEvents',sortedEvents);
                        return Promise.resolve(sortedEvents);
                    })
            }

        },
        getProjectDetails({commit, state},resetProject) {
            commit('showLoading',null,{root:true});
            if(state.projectDetails && resetProject){
                return  Promise.resolve(state.projectDetails)
            }else{
                return api.projects.getProjectDetails(state.projectId).then(
                    resp => {
                        commit('setProjectDetails', resp);
                        commit('hideLoading',null,{root:true});
                        return Promise.resolve(resp)
                    }
                )}
        },

        deleteProjectPicture({commit}, fileName){
            api.projects.deleteProjectPicture(fileName)
                .then(resp=>{
                    commit('removeProjectMedia',fileName)
                })
        },
        getProjectPictures({commit, state}) {
            commit('showLoading',null,{root:true});
            return api.projects.getProjectPictures(state.projectId)
                .then(resp => {
                    commit('setProjectMedia', resp);
                    commit('hideLoading',null,{root:true});
                    return Promise.resolve(resp);
                })
        }
    },
    mutations: {
        resetProject(state){
          state.projectEvents = [];
          state.projectDetails = null;
          state.projectMedia = [];
          state.projectId = null;
          state.tmimata = null;
        },
        removeProjectMedia(state, filename){
            state.projectMedia = state.projectMedia.filter(p=>p.FileName !== filename)
        },
        setProjectEvents(state,projectEvents){
            state.projectEvents = projectEvents;
        },
        setProjectId(state, id) {
            state.projectMedia = [];
            state.projectDetails = null;
            state.projectId = id;
        },
        setProjectMedia(state, {Pictures, Tmimata}) {
            state.projectMedia = Pictures;
            state.tmimata = Tmimata;
        },
        setProjectDetails(state, projectDetails) {
            state.projectDetails = projectDetails;
        }
    },
    getters: {
        projectEventStats:s=>({
        }),
        projectEvents: s => s.projectEvents,
        projectDetails: s => s.projectDetails,
        projectId: s => s.projectId,
        projectMedia: s => s.projectMedia,
    }

}
