export default {
    namespaced: true,
    state: {
        showSidebar: true,
        expandSidebarOnHover: false,
        sidebarItems: [],
        isMobile: false,
        showMenu:true,
        showSubMenus:true,
        enableSidebar:true,
        userAgent:'',
        layout:'web'
    },
    actions: {
        openWindow({state},url){
            window.open('#'+url, 'new','width=800,height=800,toolbar=no,menubar=no,status=no,location=no')
        },
    },
    mutations: {
        setMobile(state, value) {
            state.showSidebar = !value;
            if(value) state.expandSidebarOnHover = false;
            state.isMobile = value
        },
        toggleExpandOnHover(state){
            state.expandSidebarOnHover = !state.expandSidebarOnHover;
        },
        toggleSidebar(state){
            state.showSidebar = !state.showSidebar;
        },
        setSidebarItems(state,items){
            state.sidebarItems = items
        },
        setLayoutView(state,view){
            state.userAgent = navigator.userAgent;
            switch (view) {
                case 'desktop':
                    state.showSubMenus = false;
                    state.enableSidebar = false;
                    state.showMenu = true;
                    state.layout = 'desktop';
                    break;
            }
        }
    },
    getters: {
        userAgent:s=>s.userAgent,
        isMobile: s => s.isMobile,
        sidebarItems: s => s.sidebarItems,
        showSidebar: s => s.showSidebar,
        expandSidebarOnHover: s => s.expandSidebarOnHover,
        layout:s=>({
            sidebar:s.enableSidebar,
            menu:s.showMenu,
            subMenus:s.showSubMenus,
            layout:s.layout
        })

    }
}
