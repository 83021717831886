var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"toolbar-title"}},[_vm._v(" Αναφορές Πελατών")]),_c('filter-iterator',{attrs:{"title":"Πελάτες","is-mobile":_vm.isMobile,"show-filter-modal":"","search-label":"αναζήτηση","module-name":"partnersHandler","filters":[
                       {key:'PartnerJobName', name:'Εππάγελμα'},
                       {key:'PartnerAreaName', name:'Περιοχή'},
                       {key:'PartnerLost', name:'Χαμμένος'}
                   ],"data-set":_vm.partners},scopedSlots:_vm._u([{key:"desktop",fn:function(data){return [_c('v-data-table',{attrs:{"items":data.data,"headers":_vm.headers,"search":data.search}})]}},{key:"mobile",fn:function(data){return [_c('v-data-iterator',{attrs:{"items":data.data,"search":data.search},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',{attrs:{"dense":""}},_vm._l((props.items),function(partner){return _c('v-col',{key:partner.Partner_ID,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(partner.Partner_ID)+", "+_vm._s(partner.PartnerBrandName)+" ")]),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                   var on = ref.on;
                   var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v("Αναλυτικές Κινήσεις")])],1)],1)],1)],1),_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Επάγγελμα")]),_vm._v(" "+_vm._s(partner.PartnerJobName)+" ")],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Διεύθυνση")]),_vm._v(" "+_vm._s(partner.PartnerInvAddress)+" "+_vm._s(partner.PartnerAreaName)+" "+_vm._s(partner.PartnerPostCode)+" ")],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"icon":"","href":"tel:+3000000000"}},[_c('v-icon',[_vm._v("mdi-phone")])],1)],1)],1)],1)}),1)]}}],null,true)})]}}])}),_c('v-row',[_c('v-col',{attrs:{"lg":"4"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Χαμένοι")])],1)],1),_c('v-col',{attrs:{"lg":"4"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Ενεργοί")])],1)],1),_c('v-col',{attrs:{"lg":"4"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Χρωστάνε")])],1)],1),_c('v-col',{attrs:{"lg":"4"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Κακοπληρωτές ")])],1)],1),_c('v-col',{attrs:{"lg":"4"}},[_c('v-card',{staticStyle:{"border":"2px dashed","background-color":"transparent"},attrs:{"flat":"","ripple":""}},[_c('v-card-title',[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Προσθήκη ομάδας ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }