import axios from '../axios';
import config from "../../../config";
import PictureList from "../classes/PictureList";
import {ProjectDetails} from "../classes/ProjectDetails";

export default {
    /**
     *
     * @param projectId
     * @returns {Promise<{Pictures: *, Tmimata}>}
     */
    getProjectDetails(projectId) {
        return axios.get(config.host + '/projects/ProjectDetails', {
            params: {
                Project_ID: projectId,
            }
        }).then(resp => {
            return new ProjectDetails(resp.data.Data)
        })
    },

    getProjectPictures(projectId) {
        return axios.get(config.host + '/images/GetProjectPictures', {
            params: {
                Project_ID: projectId
            }
        }).then(resp => {
            return {
                Tmimata: resp.data.Data.Tmimata,
                Pictures: resp.data.Data.Pictures.map(p => new PictureList(p))
            }
        })
    },
    deleteProjectPicture(fileName) {
        return axios.post(config.host + '/images/DeletePicture', {
            FileName: fileName
        })
    },
    /**
     * @desc Επιστρέφει τις ζώνες των ανυψωτικών για συντηρήση
     * @returns {Promise<Promise<*>>}
     */
    getProjectsZones() {
        return axios.get(config.host + '/projects/zones').then(resp => resp.data.Data)
    },

    /**
     * @desc Επιστρέφει τα δρομολόγια των ζωνών των ανυψωτικών για συντηρήση
     * @param zoneId
     * @returns {Promise<Promise<*>>}
     */
    getDayGroupsOfZone(zoneId) {
        return axios.get(config.host + '/projects/DaygroupsPerZone',{
            params:{
                Zone_ID:zoneId
            }
        }).then(resp => resp.data.Data)
    },


}
