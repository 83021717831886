import Dexie from "dexie";
import config from "@/config";

var schema = new Dexie(config.dbName);

schema.version(config.dbVersion).stores({
    Partners:
        "Partner_ID,Sync_ID"
})


export default schema;
