<template>
    <v-card class="mb-2" outlined flat>
        <v-toolbar color="transparent" dense height="35" flat>
            <v-toolbar-title> {{filter.name}}</v-toolbar-title>
        </v-toolbar>
        <v-list  color="transparent"  dense max-height="300" class="overflow-y-auto">
            <v-list-item color="primary" v-if="filter.unique"  @click="clearFiltersOfKey(filter.key)">
                <v-list-item-action>
                    <v-checkbox color="primary" hide-details disabled />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title> Όλα </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item color="primary" dense v-for="i in  filter.data" link v-if="!(i.value === null)"
                         @click="addFilter({filterKey:filter.key,value:i.value,unique:filter.unique})">
                <v-list-item-action>
                    <v-checkbox color="primary" hide-details disabled :value="isFilterItemActive(i.value)"/>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title> {{filterItemText(i)}}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    ({{i.count}})
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
    export default {
        name: "FilterList",
        props: {
            filter: Object,
            isFilterItemActive: Function,
            addFilter: Function,
            clearFiltersOfKey: Function,
        },
        methods :{
            filterItemText(item) {
                return item.alias !== null ? item.alias : item.value
            }
        }
    }
</script>

<style scoped>

</style>
