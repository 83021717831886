<template>
    <v-card :flat="flat" :height="cardHeight">
        <v-toolbar flat v-if="showToolbar">
            <slot name="toolbar-title">
                <v-toolbar-title v-if="desktopToolbarTitle">
                    {{mobileBtnLabel}}
                </v-toolbar-title>
            </slot>
            <v-spacer/>
            <v-btn small outlined fab flat color="primary" v-if="expandable"  @click="toggleMenu()"><v-icon>{{icon}}</v-icon> </v-btn>
        </v-toolbar>
        <slot v-if="isOpen" name="content"/>
    </v-card>
</template>

<script>
    export default {
        name: "Expandable",
        data:()=>({
           isOpen:true,
            icon:'mdi-chevron-up'
        }),
        props: {
            expandable: Boolean,
            mobileBtnLabel: String,
            desktopToolbarTitle:Boolean,
            height:{
                type:String,
                default:'100%'
            },
            flat: {type: Boolean, default: null, required: false}
        },
        methods:{
          toggleMenu(){
              this.isOpen = !this.isOpen;
              this.icon = this.isOpen?'mdi-chevron-up':'mdi-chevron-down'
          }
        },
        computed:{
            showToolbar(){
                return this.desktopToolbarTitle || this.expandable
            },

            cardHeight(){
                return !this.expandable?this.height:''
            }
        }
    }
</script>

<style scoped>

</style>
