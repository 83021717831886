<template>
    <div>
        <portal to="toolbar-title"> Ανελκυστήρας: #{{projectId}}</portal>
        <v-row >
            <v-col lg="2" md="3" sm="12" v-if="layout.subMenus">
                <expandable :expandable="isMobile" desktop-toolbar-title mobile-btn-label="Επιλογές">
                    <template v-slot:content>
                        <v-list color="transparent" shaped rounded>
                            <v-list-item  :to="'/project/'+projectId+'/details'">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Στοιχεία Ανελκυστήρα
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item  :to="'/project/'+projectId+'/events'">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Βλάβες/Συντηρήσεις
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item  :to="'/project/'+projectId+'/media'">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Εικόνες
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </template>
                </expandable>
            </v-col>
            <v-col lg="10" md="9" sm="12">
                <router-view>
                </router-view>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import Expandable from "../../wl-core/components/Expandable";

    export default {
        name: "Project",
        components: {Expandable},
        created() {
            console.log(this.$route.params.id);
            this.setProjectId(this.$route.params.id);
            this.getProjectDetails();
        },
        computed:{
            ...mapGetters('project',[
                "projectId",
                "projectDetails"
            ]),
            ...mapGetters('layout',[
                'layout',
                "isMobile"
            ])
        },
        methods:{

            ...mapActions('project',[
               "getProjectDetails"
            ]),
            ...mapActions('layout',[
                "openWindow"
            ]),
            ...mapMutations('project',[
                "setProjectId"
            ])
        },

    }
</script>

<style scoped>

</style>
